import React, { useContext } from 'react'
import {Link} from "react-router-dom"
import {BsFillHeartbreakFill} from "react-icons/bs"
import UserContext from '../../frontendTestContext/UserContext'

const OopsX = () => {
  const userContextUsed = useContext(UserContext)
  return (
    <div className='oops'>
      <div className="content">
        <div className="not-found">
            <BsFillHeartbreakFill />
            <span>401</span>
            <h2>Access Denied</h2>
            {userContextUsed.isLoggedIn ? <p>You don't have access to this page. Update your profile</p> : <p>You don't have access to this page. SignIn into your account to view this page</p>}
            {userContextUsed.isLoggedIn === false && <Link to={"/login"}>
              <p>SIGN-IN</p>
            </Link>}
        </div>
      </div>
    </div>
  )
}

export default OopsX