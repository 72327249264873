import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs'
// import { FaShoppingCart } from 'react-icons/fa'
import { HiMenuAlt3 } from 'react-icons/hi'
import { NavLink, useLocation } from 'react-router-dom'
import logoImg from "../../assets/logo.png"
import UserContext from '../../frontendTestContext/UserContext'
import { useContext } from 'react'
import TopBanner from '../top-banner/Top_Banner'

const Nav = (props) => {
    const userContextUsed = useContext(UserContext)
    const location = useLocation()
    // const isLoggedIn = userContextUsed.isLoggedIn
    // const user = userContextUsed.user
    const cartData = userContextUsed.cart
    const productsInCart = cartData.items
  return (
    <div className={`nav ${location.pathname.includes("search") && "in-search"}`}>
        <TopBanner/>
        <div className='sub-nav'>
        <div className="logo">
            <NavLink to={"/"} onClick={()=>{
                props.handleLoader(true, false)
            }} >
                <img src={logoImg} alt="shoptinga-logo" />
                {/* <FaShoppingCart color='#fff' /> */}
            </NavLink>
        </div>
        <div className="links-holder">
            
            <NavLink to={"/search"}>
                <BsSearch size={17}/>
            </NavLink>
            <NavLink  to={`${(location.pathname.includes("cart")&&productsInCart.length < 1)? "/shop" : "/cart"}`} className='cart'>
                <AiOutlineShoppingCart size={23}/>
                <div className="number">
                    {productsInCart.length}
                </div>
            </NavLink>
            <div className="hamburger" onClick={props.showMenu}>
                <HiMenuAlt3 size={26} />
            </div>
        </div>
        </div>
      
       
        
    </div>
  )
}

export default Nav
