import React from 'react'
import { BiExitFullscreen } from 'react-icons/bi'

const BigImg = (props) => {
  return (
    <div className={`big-img ${props.showbigImg && "show-me"}`}>
        <div className="content">
            <img src={props.bigImgUrl} alt="fullscreen" />
            <div className="controls">
                <div className="close" onClick={props.hideBigImg} style={{width: "fit-content", color: "#000", borderRadius: "100px", padding: "0 15px", background: "rgb(255, 166, 0"}}>
                    <BiExitFullscreen style={{marginRight: "10px", color: "#000"}} /> <p style={{fontSize: "18px"}}>minimize</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BigImg