import React from 'react'

const MiniLoader = () => {
  return (
    <div className='preloader mini'>
        <div className="preloader-circle"></div>
    </div>
  )
}

export default MiniLoader