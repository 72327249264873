import React from 'react'
import { Link } from 'react-router-dom'
import {MdDashboardCustomize, MdModeEditOutline} from "react-icons/md"
// import defaultImg from "../../assets/signup.jpg"
import { FaArrowRight, FaDollarSign, FaExternalLinkAlt } from 'react-icons/fa'
import { useContext } from 'react'
import UserContext from '../../frontendTestContext/UserContext'
import Footer from '../../components/footer/Footer'
import { IoSparkles } from 'react-icons/io5'
// import axios from 'axios'

const Account = (props) => {
  const userContextUsed = useContext(UserContext)
  // const isLoggedIn = userContextUsed.isLoggedIn
  const user = userContextUsed.user
  // console.log(user)

  const purchasedProducts = user.purchases

  const purchasedProductsEl = purchasedProducts.map((purchase) => {
    return (
      <div className="product" key={purchase.slug}>
        <img src={purchase.thumbnail} alt="owner" />
        <div className="name">
          {purchase.title}
        </div>
        <Link to={`/product/${purchase.slug}`}>
          View <FaExternalLinkAlt />
        </Link>
      </div>
    );
  });

  return (
    <div className='account'>
      <div className="content">
        <div className="heading">
          <h2>{user.isSeller ? "Seller Profile" : "Profile"}</h2>
          <Link to={"/account/edit"}>
            Edit <MdModeEditOutline />
          </Link>
        </div>

        <div className="first-section" style={{marginBottom: "55px"}}>
          <div className="card incomplete">
            <Link to={"/account/edit"} className='edit--profile'>
              <MdModeEditOutline />
            </Link>
            <img src={user.userImg} alt='this user' />
            <h3>Welcome, <span>{user.userName}</span></h3>
            <div className="account-completion-level">
              {user.isSeller === false ? <p style={{display: "none"}}>
                Take a Few More steps to complete your Account Setup
              </p> : <p style={{marginBottom: "30px"}}>
                {user.sellerInfo.info.bio === "string" ? "......" : user.sellerInfo.info.bio}
              </p>}
              {/* {(user.isSeller === false) && <div className="filler">
                <div className="circle">
                  85%
                </div>
              </div>} */}
              {user.isSeller===false ? <Link to={"/account/edit"} className='edit-account__link'><span>Edit Profile</span> <FaArrowRight /> </Link> : <Link to={"/dashboard"} className='edit-account__link'><span>My Dashboard</span> <MdDashboardCustomize style={{transform: "rotate(0deg)"}}/> </Link>}
            </div>
          </div>
        </div>
        
        <div className="second-section">
          <div className="title">
            <h2>My Purchases</h2>
            <p><FaDollarSign /></p>
          </div>
          {purchasedProducts.length > 0 ? <div className="container">
            {purchasedProductsEl}
          </div> : <div className='container' style={{backgroundColor: "transparent"}}><p style={{fontWeight: "500", fontSize: "18px", marginBottom: "50px"}}>You haven't made any Purchases yet.</p></div>}
        </div>
        
        {user.isSeller === false && <div className="third-section">
          <Link onClick={()=>{
            props.handleLoader(true, false)
          }} to={"/become-a-seller"}>
            <IoSparkles /> Become a Seller
          </Link>
        </div>}
        <Footer />
      </div>
    </div>
  )
}

export default Account