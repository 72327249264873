import React, { useContext, useEffect, useState } from 'react'
import { FaInstagram, FaLinkedinIn, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import UserContext from '../../frontendTestContext/UserContext'

const Footer = () => {
    const gotoSection = (page, section) =>{
        console.log(page, section)
        const sectionEl = document.getElementById(section)

        sectionEl.scrollIntoView()
    }

    const userContextUsed = useContext(UserContext)

    const [currentYear, setCurrentYear] = useState("...")

    useEffect(()=>{
        const currentYear = new Date().getFullYear();
        setCurrentYear(currentYear)
    },[])
  return (
    <div className='footer'>
        <div className="social-links">
                <h3>Follow Us</h3>
                <div className="links-holder">
                    <Link to={"/"}>
                        <FaWhatsapp />
                    </Link>
                    <a target='_blank' rel='noreferrer' href="https//:Instagram.com/shoptingahq">
                        <FaInstagram />
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/Shoptingahq">
                        <FaTwitter />
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://linkedin.com/company/shoptingahq">
                        <FaLinkedinIn />
                    </a>
                </div>
            </div>
            <div className="company">
                <h3>Company</h3>
                <div className="links-holder">
                    <Link to={"/"}>
                        <p>
                            Home
                        </p>
                    </Link>
                    <Link to={"/shop"}>
                        <p>
                            Shop
                        </p>
                    </Link>
                    <Link to={"/search"}>
                        <p>
                            Search
                        </p>
                    </Link>
                    {userContextUsed.isLoggedIn ? <Link to={"/become-a-seller"}>
                        <p>
                            Logout
                        </p>
                    </Link> : <Link to={"login"}>
                        <p>
                            SignIn
                        </p>
                    </Link> }
                    <Link style={{display:"none"}} to={"/"} onClick={()=>{
                        gotoSection("home", "about-us")
                    }}>
                        <p>
                            About Us
                        </p>
                    </Link>
                </div>
            </div>
            <div className="resources">
                <h3>Resources</h3>
                <div className="links-holder">
                    <Link to={"/become-a-seller"}>
                        <p>
                            Become a Seller
                        </p>
                    </Link>
                    <a href="https://wa.me/+2349060620279" target="_blank" rel='noreferrer'>
                        <p>
                            Help & Support
                        </p>
                    </a>
                </div>
            </div>
            <div className="legal">
                <h3>Legal</h3>
                <div className="links-holder">
                    <Link to={"/terms-of-service"}>
                        <p>
                            Terms & Conditions
                        </p>
                    </Link>
                </div>
            </div>
        <div className="copyright">
            <p>
                © {currentYear} Shoptinga inc. All Rights Reserved.
            </p>
        </div>

    </div>
  )
}

export default Footer
