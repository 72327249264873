import React, { useContext, useState } from 'react'
import { FaHeart, FaMinus, FaPlus } from 'react-icons/fa'
import { FiExternalLink } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import UserContext from '../../frontendTestContext/UserContext'
import axios from 'axios'

const ProductHome = (props) => {
    const userContextUsed = useContext(UserContext)
    const navigate = useNavigate()

    async function likeProduct(productId){
        const access = localStorage.getItem("access")
        const url = `https://shoptinga.onrender.com/api/products/heart/${productId}`
        if(access && userContextUsed.isLoggedIn){
        try{
            const response = await axios.post(url,{}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${access}`,
                'Accept': 'application/json',
            }
            })
            console.log(response.data)
        } catch(error){
            console.error(error)
            userContextUsed.functions.handleNotification("Failed to Like product on server", "warn")
        }
        } else{
        setTimeout(()=>{
            navigate("/login")
            userContextUsed.functions.handleNotification("SignIn to your Account to Like products 👍", "warn")
        }, 10)
        }
    }

    async function unLikeProduct(productId){
        const access = localStorage.getItem("access")
        const url = `https://shoptinga.onrender.com/api/products/heart/${productId}`
        if(access && userContextUsed.isLoggedIn){
        try{
            const response = await axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${access}`,
                'Accept': 'application/json',
            }
            })
            console.log(response.data)
        } catch(error){
            console.error(error);
            userContextUsed.functions.handleNotification("Failed to delete Like on server", "warn")
        }
        } else{
        setTimeout(()=>{
            navigate("/login")
            userContextUsed.functions.handleNotification("SignIn to your Account to Like products 👍", "warn")
        }, 10)
        }
    }

    const [isLiked, setIsLiked] = useState(false)
  return (
    <div className={props.className} key={props.detailsFromBackend.productId}>
        <div className="top" style={{
            backgroundImage: `linear-gradient(#0000, #000e), url(${props.detailsFromBackend.productUrls.productImgUrl})`,
        }}>
            <Link onClick={()=>{
                props.handleLoader(true, false)
            }} to={`/product/${props.detailsFromBackend.productUrls.productUrl}`} className='product__link'>
                <FiExternalLink />
            </Link>
            <div className="product-details">
                <div className="product-name">
                    {props.detailsFromBackend.productName}
                </div>
                <div className="product-price">
                    ₦{props.detailsFromBackend.productPriceInDollar}
                </div>
            </div>
        </div>
        <button style={{
            background: "transparent",
            position: "absolute",
            top: "15px",
            right: "15px",
            fontSize: "20px",
            zIndex: "100",
            cursor: "pointer",
            border: "none",
            backdropFilter: "blur",
            transition: "all o.5s ease",
        }} className={isLiked ? "heart isLiked" : "heart"} onClick={()=>{
            if(isLiked){
                setIsLiked(false)
                unLikeProduct(props.detailsFromBackend.productId)
            } else{
                setIsLiked(true)
                likeProduct(props.detailsFromBackend.productId)
            }
        }}> {isLiked ? <FaHeart color='red' style={{color:"red"}} /> : <FaHeart color='#fff6' style={{color:"#fff6"}} />} </button> 
        <div className={props.cartStatus ? "bottom in-cart" : "bottom"} onClick={()=>{
            if (props.cartStatus){
                props.removeFromCart(props.detailsFromBackend.productId)
            }
            else {
                props.addToCart(props.detailsFromBackend.productId)
            }
        }}>
            {props.cartStatus ? <FaMinus />:
            <FaPlus />}
            <span>
                {props.cartStatus ? "Remove from Cart": "Add to Cart"}
            </span>
        </div>
    </div>
  )
}

export default ProductHome
