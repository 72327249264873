import React, { useContext } from 'react'
import { BsMoon } from 'react-icons/bs'
import { FiLogOut } from 'react-icons/fi'
import { Link, NavLink, useLocation } from 'react-router-dom'
import UserContext from '../../frontendTestContext/UserContext'
// import { FaUserCircle } from 'react-icons/fa'
import { BiLogInCircle } from 'react-icons/bi'

const Menu = (props) => {
    const userContextUsed = useContext(UserContext)
    const isLoggedIn = userContextUsed.isLoggedIn
    const location = useLocation()
    // const avatarImages=[
    //     "signup",
    //     "bear",
    //     "boy",
    //     "cat",
    //     "empathy",
    //     "gamer",
    //     "man",
    //     "panda",
    // ]
  return (
    <div className='menu'>
        <div className="heading">
            {isLoggedIn ? <Link onClick={()=>{
                props.handleLoader(true, true)
                props.hideMenu()
            }} to={"/account"}>
                <img src={userContextUsed.user.userImg} alt="" /> {userContextUsed.user.userName ? `~ ${userContextUsed.user.userName}` : "Profile"}
            </Link> : <Link onClick={()=>{
                props.hideMenu()
            }} to={"/login"}>
                Login <BiLogInCircle />
            </Link>}

            <div onClick={()=>{
                // props.handleLoader(true, true)
                props.hideMenu()
            }} style={{display: "none"}} className="dark-theme-toggle">
                <BsMoon />
            </div>
        </div>
        <div className="links-holder">
            <NavLink onClick={()=>{
                props.handleLoader(true, true)
                props.hideMenu()
            }} to={"/"}>
                 Home
            </NavLink>
            <NavLink onClick={()=>{
                props.handleLoader(true, true)
                props.hideMenu()
            }} to={"/shop"} className={`${location.pathname.includes("search") && "active"} ${location.pathname.includes("cart") && "active"}  ${location.pathname.includes("/product") && "active"}`}>
                 Shop
            </NavLink>
            {userContextUsed.isLoggedIn && <NavLink onClick={()=>{
                props.handleLoader(true, true)
                props.hideMenu()
            }} to={"/dashboard"}>
                 Dashboard
            </NavLink>}
            {/* <NavLink onClick={()=>{
                props.handleLoader(true, true)
                props.hideMenu()
            }} to={"/search"}>
                 Search
            </NavLink> */}
            {userContextUsed.isLoggedIn && <NavLink onClick={()=>{
                props.handleLoader(true, true)
                props.hideMenu()
            }} to={"/account"}  className={`${location.pathname.includes("account") && "active"}`}>
                 Profile
            </NavLink>}
        </div>
        {userContextUsed.isLoggedIn && <div onClick={()=>{
            props.hideMenu()
            userContextUsed.functions.confirmLogout()
        }} className="logout__link">
            <FiLogOut />
        </div>}

        <div className="bg"></div>
    </div>
  )
}

export default Menu