import React from 'react'
import {RiSignalWifiOffLine} from "react-icons/ri"

const NoNetwork = (props) => {
  return (
    <div className='no-network'>
        <RiSignalWifiOffLine />
        <div className="info">
          <h5>Network Error</h5>
          {props.errorMessage ? <p>{props.errorMessage} <br /> {props.errorAction && <span>{props.errorAction}</span>} </p> : <p>Failed to connect to server <br /> <span onClick={()=>{window.location.reload()}}>Reload</span> page or try again later</p>}
        </div>
    </div>
  )
}

export default NoNetwork