import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { FaTimes } from 'react-icons/fa'
import {Link, useLocation} from "react-router-dom"
import UserContext from '../../frontendTestContext/UserContext'

const BecomeSellerToView = () => {
  const location = useLocation()
  const [showMe, setShowMe] = useState(false)
  // const navigate = useNavigate()
  const userContextUsed = useContext(UserContext)
  useEffect(()=>{
    const path = location.pathname
    if(userContextUsed.user.isSeller === false){
      if(path.includes("dashboard")||path.includes("create-item")||path.includes("delete-item")||path.includes("edit-item")){
        setShowMe(true)
      } else{
        setShowMe(false)
      }
    } else{
      setShowMe(false)  
    }
  }, [location.pathname, userContextUsed])
  return (
    <div className={showMe ? 'become-seller-to-view show': 'become-seller-to-view'}>
        <div className="gotohome" onClick={()=>{
          setShowMe(false)
        }}>
          <FaTimes />
        </div>
        <div className="holder">
            {userContextUsed.isLoggedIn ? <Link to={"/become-a-seller"}>
                Become a Seller to View this Page
            </Link> : <Link to={"/login"}>
                Login to access your Dashboard
            </Link>}
        </div>
    </div>
  )
}

export default BecomeSellerToView