import { IoSparkles } from 'react-icons/io5'
import { HiPencil } from 'react-icons/hi2'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../components/footer/Footer'
import React, { useContext, useEffect, useState } from 'react'
import {FaArrowRight, FaCheck} from "react-icons/fa"
import { AiOutlineInfoCircle } from 'react-icons/ai'
import UserContext from '../../frontendTestContext/UserContext'
import axios from 'axios'

const BecomeASeller = (props) => {
    const [userInfo, setUserInfo] = useState({
        username: "",
        avatar: 0,
        bio: "",
        transaction: {
            transactionType: "crypto",
            crypto: {
                cryptoCurrency: "Usdt",
                walletAddress: "",
            },
            fiat: {
                bankAccount: "",
                bankName: "",
            },
        },
        imgFile: "",
    })

    const avatarImages=[
        "signup",
        "bear",
        "boy",
        "cat",
        "empathy",
        "gamer",
        "man",
        "panda",
    ]

    const navigate = useNavigate()


    const userContextUsed = useContext(UserContext)
    const user = userContextUsed.user
    if(user.isSeller){
        navigate("/account/edit")
    }

    useEffect(()=>{
        if(userContextUsed.isLoggedIn){
            setUserInfo((prev)=>{
                return ({
                    ...prev,
                    username: user.userName,
                    avatar: user.userAvatar,
                })
            })
        }
    }, [userContextUsed.isLoggedIn, user])

    function toggleTransaction(value){
        setUserInfo((prev)=>{
            return({
                ...prev,
                transaction: {
                    ...prev.transaction,
                    transactionType: value,
                },
              })
        }) 
    }
    
    function changeWalletAddress(value){
        setUserInfo((prev)=>{
            return({
                ...prev,
                transaction: {
                    ...prev.transaction,
                    crypto: {
                        ...prev.transaction.crypto,
                        walletAddress: value
                    }
                },
            })
        }) 
    }
    
    
      function handleChange(e){
        const name = e.target.name
        if(name === "tncs"){
          setUserInfo((prev)=>{
            return({
              ...prev,
              [name]: e.target.checked
            })
          })
        } else{
            setUserInfo((prev)=>{
                return({
                    ...prev,
                    [name]: e.target.value
                })
            })
        }
    }
    
    const id = user.userId
    const access = window.localStorage.getItem("access")
    
    async function handleSubmit(e){
        e.preventDefault()
        console.clear()
        
        if(user.isSeller === false){
            props.handleLoader(true)
            if(userInfo.transaction.transactionType === "crypto"){
                const data = {
                    "avatar": userInfo.avatar,
                    "account_balance": 0,
                    "crypto_wallet_id": userInfo.transaction.crypto.walletAddress,
                    "crypto_type": userInfo.transaction.crypto.cryptoCurrency,
                    "bank_account": "",
                    "bank_name": "",
                    "is_seller": true,
                    "commision": 3,
                    "bio": userInfo.bio
                };
                // console.log(data)
                
                
                // console.log(id)
                try {
                    const response = await axios.put(`https://gateway.shoptinga.com/api/profiles/${id}/`,{
                        ...data
                    },{
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `JWT ${access}`,
                            'Accept': 'application/json',
                        },
                    });
                    console.log('Request successful. Response:', response.data);
                    props.updateUser()
                    navigate("/account")
                }   catch (error) {
                    console.error('Error occurred:', error);
                    // alert("Failed to Update Account")
                    props.handleLoader(false)
                }
            }
    
            if(userInfo.transaction.transactionType === "fiat"){
                const data = {
                    "account_balance": 0,
                    "crypto_wallet_id": "",
                    "crypto_type": "",
                    "bank_account": userInfo.transaction.fiat.bankAccount,
                    "bank_name": userInfo.transaction.fiat.bankName,
                    "is_seller": true,
                    "commision": 3,
                    "avatar": userInfo.avatar,
                    "bio": userInfo.bio
                };
                // console.log(data)
        
                const id = user.userId
                
                try {
                    const response = await axios.put(`https://gateway.shoptinga.com/api/profiles/${id}/`, {...data}, {
                        headers: {
                          // Accept: 'application/json',
                          // Authorization: `Bearer ${access}`,
                          'Content-Type': 'application/json',
                          'Authorization': `JWT ${access}`,
                          'Accept': 'application/json',
                        },
                      });
                    console.log('Request successful. Response:', response.data);
                    props.updateUser()
                    userContextUsed.functions.handleNotification("Profile u[pdated successfully", "success")
                    navigate("/account")
                }   catch (error) {
                    console.error('Error occurred:', error);
                    userContextUsed.functions.handleNotification("Oops an error occured", "error")
                    // alert("Failed to Update Account")
                    props.handleLoader(false)
                }
            }
        } else{
            navigate("/account/edit")
            userContextUsed.functions.handleNotification("You are already a seller", "info")
        }
      }

    const [avatarUi, setAvatarUi] = useState(false)

    
    function toggleAvatar(value){
        setUserInfo((prev)=>{
            return({
                ...prev,
                avatar: value,
              })
        })
        setAvatarUi(false) 
    }

  return (
    <div className={`become-a-seller-big signup`}>
        <div className="holder">
            <p>
                Do you want to become a Seller on Shoptinga?. You consent to our <Link to={"/terms"}>Terms and Conditions.</Link>
            </p>
            <div className="btns">
                <button onClick={()=>{
                    props.handleLoader(true, false)
                }}>Confirm <IoSparkles /></button>
                <Link to={"/account"}>Cancel</Link>
            </div>
        </div>
        <div className="mini-backdrop"></div>
        <div className="content">
            <h2>
                Become a Seller
            </h2>
            <form onSubmit={handleSubmit} className="form">
            <div className={avatarUi ? "image x hover" : "image x"}>
                <div className="pointer" onClick={()=>{
                    setAvatarUi(true)
                }}>
                    <HiPencil />
                </div>
              <img onClick={()=>{
                    setAvatarUi(true)
                }} src={`./${avatarImages[userInfo.avatar]}.png`} alt="" />
              <div className="avatars-holder">
                
                <div onClick={()=>{
                    toggleAvatar(0)
                }} className={`img-holder ${userInfo.avatar === 0 && "active"}`}>
                    <div className="selected">
                        <FaCheck />
                    </div>
                    <img src="./signup.png" alt="" />

                </div>
                
                <div onClick={()=>{
                    toggleAvatar(1)
                }} className={`img-holder ${userInfo.avatar === 1 && "active"}`}>
                    <div className="selected">
                        <FaCheck />
                    </div>
                    <img src="./bear.png" alt="" />

                </div>
                
                <div onClick={()=>{
                    toggleAvatar(2)
                }} className={`img-holder ${userInfo.avatar === 2 && "active"}`}>
                    <div className="selected">
                        <FaCheck />
                    </div>
                    <img src="./boy.png" alt="" />

                </div>
                
                <div onClick={()=>{
                    toggleAvatar(3)
                }} className={`img-holder ${userInfo.avatar === 3 && "active"}`}>
                    <div className="selected">
                        <FaCheck />
                    </div>
                    <img src="./cat.png" alt="" />

                </div>
                
                <div onClick={()=>{
                    toggleAvatar(4)
                }} className={`img-holder ${userInfo.avatar === 4 && "active"}`}>
                    <div className="selected">
                        <FaCheck />
                    </div>
                    <img src="./empathy.png" alt="" />

                </div>
                
                <div onClick={()=>{
                    toggleAvatar(5)
                }} className={`img-holder ${userInfo.avatar === 5 && "active"}`}>
                    <div className="selected">
                        <FaCheck />
                    </div>
                    <img src="./gamer.png" alt="" />

                </div>
                
                <div onClick={()=>{
                    toggleAvatar(6)
                }} className={`img-holder ${userInfo.avatar === 6 && "active"}`}>
                    <div className="selected">
                        <FaCheck />
                    </div>
                    <img src="./man.png" alt="" />

                </div>
                
                <div onClick={()=>{
                    toggleAvatar(7)
                }} className={`img-holder ${userInfo.avatar === 7 && "active"}`}>
                    <div className="selected">
                        <FaCheck />
                    </div>
                    <img src="./panda.png" alt="" />

                </div>
              </div>
            </div>
            <div className="inpt">
              <label htmlFor="userName">
                Brand Name*
              </label>
              <input required name='username' value={userInfo.username} onChange={()=>{
                setUserInfo((prev)=>{
                    return({
                        ...prev,
                        username: prev.username
                    })
                })
              }} type="text" id='userName' />
            </div>
            <div className="inpt">
              <label htmlFor="userName">
                Bio
              </label>
              <input required name='bio' value={userInfo.bio} onChange={handleChange} type="text" id='bio' />
            </div>
            <div className="transaction">
                <div className="heading">
                    <h3>Mode of Transactions</h3>
                    <div className="corf">
                        <div className={`to-crypto ${userInfo.transaction.transactionType==="crypto" && "active"}`} onClick={()=>{toggleTransaction("crypto")}}>
                            Crypto
                        </div>
                        <div className={`${userInfo.transaction.transactionType==="fiat" && "active"}`} onClick={()=>{toggleTransaction("fiat")}}>
                            Fiat
                        </div>
                    </div>
                </div>
                {userInfo.transaction.transactionType === "crypto" && <div className="body">
                    <div className="crip-type">
                        <p>
                            Cryptocurrency <Link to={"/terms"}><AiOutlineInfoCircle /></Link>
                        </p>
                        <div className="current">
                            {userInfo.transaction.crypto.cryptoCurrency === "Usdt" && "usdt"}
                            {userInfo.transaction.crypto.cryptoCurrency === "Usdc" && "usdc"}
                        </div>
                    </div>
                    {userInfo.transaction.transactionType === "crypto" && <div className="wallet-address">
                        <input type="text" name='walletAddress' value={userInfo.transaction.crypto.walletAddress} onChange={(e)=>{
                            changeWalletAddress(e.target.value)
                        }} min={7} placeholder='Wallet Address' required />
                    </div>}
                </div>}
                {userInfo.transaction.transactionType === "fiat" && <div className="body">
                    <div className="inpt">
                        <input required name='bankAccount' value={userInfo.transaction.fiat.bankAccount} onChange={(e)=>{
                            setUserInfo((prev)=>{
                                return({
                                    ...prev,
                                    transaction: {
                                        ...prev.transaction,
                                        fiat: {
                                            ...prev.transaction.fiat,
                                            bankAccount: e.target.value
                                        }
                                    }
                                })
                            })
                        }} type="number" placeholder='Account Number' minLength={8} id='bankAccount' />
                        <input required name='bankName' value={userInfo.transaction.fiat.bankName} onChange={(e)=>{
                            setUserInfo((prev)=>{
                                return({
                                    ...prev,
                                    transaction: {
                                        ...prev.transaction,
                                        fiat: {
                                            ...prev.transaction.fiat,
                                            bankName: e.target.value
                                        }
                                    }
                                })
                            })
                        }} placeholder='Bank Name' type="text" id='bankName' />
                    </div>
                </div>}
            </div>
            <button>
              Done <FaArrowRight />
            </button>
            </form>
            <Footer />
        </div>
    </div>
  )
}

export default BecomeASeller
