import React from 'react'
import { FaArrowRight } from 'react-icons/fa'

const TopBanner = () => {
  return (
    <div className='top-banner' style={{
        width: '100%',
        padding: 8,
        textAlign: 'center',
        backgroundColor: '#111',
        color: 'white'
    }}>
            <p>Are you a physical product seller and want to sell with Shoptinga?{' '} 
                <a style={{color: '#FFA600', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', gap: 8}} href='https://storefront.shoptinga.com'>Visit our storefront<FaArrowRight/></a></p>
        </div>
  )
}

export default TopBanner