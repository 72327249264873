import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BiFullscreen } from 'react-icons/bi'
import { FaPlus, FaMinus, FaSearch } from 'react-icons/fa'
import { BsShareFill } from 'react-icons/bs'
import { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import UserContext from '../../frontendTestContext/UserContext'
import Oops from '../oops/Oops'
import { CgDanger } from 'react-icons/cg'

const Product = (props) => {
  const [productData, setProductData] = useState({
    owner: {
      username: "",
      userImg: "./image-not-found.png",
    },
    ownerId: "",
    id: "",
    name: "",
    about: "",
    hasBeenPurchasedByThisUser: false,
    price: 0.00,
    productImg: "", 
    highlights: [
      {
        title: "",
      },
      {
        title: "",
      },
      {
        title: "",
      },
    ],
    gallery: {
      images: [
        {
          imgName: "",
          imgUrl: "./image-not-found.png"
        },
        {
          imgName: "",
          imgUrl: "./image-not-found.png"
        },
        {
          imgName: "",
          imgUrl: "./image-not-found.png"
        },
      ]
    },
    productContent: "",
  })

  const productImagesEl = productData.gallery.images.map((image)=>{
    return (
      <div style={image.imgUrl.length < 1 ? {display: "none"} : {display: "flex"}}  className="media img" key={Math.floor(Math.random()*10000)+100+image.imgName}>
        <div className="options" onClick={()=>{
          showbigImg(image.imgUrl)
        }}>
            <BiFullscreen />
        </div>
        <img src={image.imgUrl} alt="product" />
      </div>
    )
  })
  const productHighlightsEl = productData.highlights.map((highlight)=>{
    return(
      <div key={Math.floor(Math.random()*10000)+100+highlight.title}>
        {highlight.title}
      </div>
    )
  })

  function showbigImg(url){
    props.showbigImg(url)
  }

  function shareLink(url){
    if (navigator.share) {
      navigator.share({
        url: url
      })
        .then(() => {
          console.log('Link shared successfully!');
        })
        .catch((error) => {
          console.error('Error sharing link:', error);
        });
    } else {
      console.error('Share API is not supported in this browser.');
    }
  }



  const {productId} = useParams()
  const userContextUsed = useContext(UserContext)
  const shopData = userContextUsed.shop
  const user = userContextUsed.user
  const cartData = userContextUsed.cart
    
  const productListX = shopData.products;
  
  const productList = productListX.map((product) => {
      const hasBeenAddedToCart = cartData.items.some(
      (item) => item.itemId === product.productDetailsFromBackend.productId);

          return {
              ...product,
              productDetailsFromBrowser: {
              ...product.productDetailsFromBrowser,
              hasBeenAddedToCart: hasBeenAddedToCart,
          },
      };
  });

  const filteredProducts = productList.filter((product)=>{
    return product.productDetailsFromBackend.productUrls.productUrl === productId
  })
  const thisProduct = filteredProducts[0]
  const browserDetails = thisProduct?.productDetailsFromBrowser
  const isInCart = browserDetails?.hasBeenAddedToCart

  const [productNotFound, setProductNotFound] = useState(false)
  
  useEffect(()=>{
    
  if (shopData.products) {
  const filteredProducts = shopData.products.filter((product) => {
    const idToCheck = String(productId); // Convert to string using String() method
    const productIdAsString = String(product.productDetailsFromBackend.productUrls.productUrl); // Convert product ID to string
    // console.log('Product ID:', productIdAsString);
    // console.log('ID to Check:', idToCheck);
    // console.log('Comparison Result:', productIdAsString === idToCheck);
    return productIdAsString === idToCheck;
  });

  if (filteredProducts.length > 0) {
        setProductNotFound(false);
        const thisProduct = filteredProducts[0]
        const backendDetails = thisProduct.productDetailsFromBackend
        const productHighlights = backendDetails.productFull.productHighlights.map((highlight)=>{
          return ({
            title: highlight.highlightName,
          })
        })

        const usersPurchases = userContextUsed.user.purchases

        const productImages = backendDetails.productMedia.images.map((image)=>{
          return ({
            imgName: image.imageName,
            imgUrl: image.imageUrl,
          })
        })

        function isProductPurchased(productId) {
          return usersPurchases.some((product) => product.id === productId);
        }

        const isPurchased = isProductPurchased(backendDetails.productId);

        userContextUsed.functions.setSellerToView(backendDetails.productOwner.userId)
        setProductData({
          owner: {
            username: backendDetails.productOwner.userName,
            userImg: "https://i.pinimg.com/736x/6b/75/ea/6b75ea3726a33d5666150e0e7c808980.jpg",
          },
          id: backendDetails.productId,
          ownerId: backendDetails.productOwner.userId,
          name: backendDetails.productName,
          about: backendDetails.productFull.productAbout,
          productImg: backendDetails.productUrls.productImgUrl,
          hasBeenPurchasedByThisUser: isPurchased,
          price: backendDetails.productPriceInDollar,
          highlights: [
            ...productHighlights
          ],
          gallery: {
            images: [
              ...productImages
            ]
          },
          productContent: backendDetails.productContent,
        })

  } else {
    setProductNotFound(true);
    console.log("Did not find that product");
  }
} else {
  setProductNotFound(true);
  console.log("Loading...");
}

  }, [productId, userContextUsed, shopData])

  const navigate = useNavigate()

  return (
    <div className='product-big'>
      {productNotFound === false ? <div className="content">
        <div className="purchase">
          <div className="price">
            ${productData.price}
          </div>
          <div className="share" onClick={()=>{
            props.handleLoader(true, true)
            shareLink(`https://shoptinga.online/product/${productId}`)
          }}>
            <BsShareFill />
          </div>
          
          {(productData.hasBeenPurchasedByThisUser || user.userId === productData.ownerId) ? <a style={{textDecoration: "none", color: "#fff"}} className="add-cart purchased" href={productData.productContent} target='_blank' rel='noreferrer' download>
            <p style={{
              fontSize: "18px",
              fontWeight: "600"
            }}>
              Download <span>{user.userId === productData.ownerId ? "Product" : "Purchase"}</span>
            </p>
          </a> : <>{user.userId === productData.ownerId ? <div style={{backgroundColor: "rgb(200, 0, 0)"}} className={isInCart ? "add-cart in-cart": "add-cart"} onClick={()=>{
                    navigate(`/dashboard`)
                }}>
            <p>
              Delete Product
            </p>
            <CgDanger />
          </div> : <div className={isInCart ? "add-cart in-cart": "add-cart"} onClick={()=>{
                    if (isInCart){
                        props.removeFromCart(productData.id)
                    }
                    else {
                        props.addToCart(productData.id)
                    }
                }}>
            {isInCart ? <FaMinus /> : <FaPlus />}
            { isInCart ? <p>
              Remove from Cart
            </p> : <p>
              Add to Cart
            </p>}
          </div>}</>}
        </div>
        <div className="heading">
          <h2>
            {productData.name}
          </h2>
          <div className="author-info">
            {user.userId === productData.ownerId ? <div className="author" onClick={()=>{
              navigate("/dashboard")
            }}>
              <p style={{padding: "5px 10px"}}>
                <span>~</span> <span>Created by You</span>
              </p>
            </div> : <button onClick={props.previewSeller} to={`/seller/${productData.owner.username}`} className="author">
              <p>
                <span>~</span> <span>Discover more from Creator</span>
              </p>
              <FaSearch style={{zIndex: "2"}}/>
            </button>}
          </div>
        </div>
        <div className="product-info">
          <div className="about">
            {productData.about}
          </div>
          <h3>Gallery</h3>
          <div className="product-images">
            <div className="media img">
              <div className="options" onClick={()=>{
                showbigImg(productData.productImg)
              }}>
                  <BiFullscreen />
              </div>
              <img src={productData.productImg} alt="product" />
            </div>
            {productImagesEl}
          </div>
          {productData.highlights.length > 0 && <div className="highlights">
              <h3>Highlights</h3>
              {productHighlightsEl}
          </div>}
          
          <>{user.userId === productData.ownerId || productData.hasBeenPurchasedByThisUser ? <div className="price">
            Price <span style={{pointerEvents: "none"}}>${productData.price}</span>
          </div> : <div className="price"  onClick={()=>{
              props.checkOut(productData.id)
            }} style={{cursor: "pointer"}}>
            Buy Now <span>₦{productData.price}</span>
          </div>}</>
          
        </div>
      </div> : <>
        <Oops />
      </>}
    </div>
  )
}

export default Product
