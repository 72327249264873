import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../../frontendTestContext/UserContext'
import { HiSparkles } from 'react-icons/hi'
import axios from 'axios'
import { FaPlus, FaTimes } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import OopsX from '../oops/OopsX'

const NewProduct = (props) => {
    const userContextUsed = useContext(UserContext)
    const user = userContextUsed.user
    const [access, setAccess] = useState(true)

    const [productInfo, setProductInfo] = useState({
        productName: "",
        productSlug: "",
        productPrice: "",
        description: "",
        category: "Softwares",
        productThumbnailFile: "",
        productGalleryFile: "",
        productOwner: "",
        productId: "",
        productContent: false,
    })

    // const shopData = userContextUsed.shop.products

    const id = user.userId
    
    const [notFound, setNotFound] = useState(false)

    useEffect(()=>{
        if(user.isSeller){
            setNotFound(false)
        } else{
            setNotFound(true)
        }
    },[user])

    const removeImgThumbnail = ()=>{
        setProductInfo((prev)=>{
          return({
            ...prev,
            productThumbnailFile: ""
          })
        })
      }

    const removeImgProductImage = ()=>{
        setProductInfo((prev)=>{
          return({
            ...prev,
            productGalleryFile: ""
          })
        })
    }

    const [fileData, setFileData] = useState({
        thumbnail: null,
        gallery: null,
        content: null
    })
    
    const handleImageChangeThumbnail = (event) => {
        const file = event.target.files[0];
        setFileData((prev)=>{
            return ({
                ...prev,
                thumbnail: file,
            })
        })
        const reader = new FileReader();
    
        reader.onload = (event) => {
        //   setImageSrc(event.target.result);
          setProductInfo((prev)=>{
            return({
                ...prev,
                productThumbnailFile: event.target.result
            })
          });
        };
    
        reader.readAsDataURL(file);
    };

    const handleImageChangeProductImage = (event) => {
        const file = event.target.files[0];
        setFileData((prev)=>{
            return ({
                ...prev,
                gallery: file,
            })
        })
        const reader = new FileReader();
        
        reader.onload = (event) => {
        //   setImageSrc(event.target.result);
            setProductInfo((prev)=>{
                return({
                    ...prev,
                    productGalleryFile: event.target.result
                })
            });
        };
    
        reader.readAsDataURL(file);
    };

    const uploadContent = (event)=>{
        const file = event.target.files[0]
        setFileData((prev)=>{
            return ({
                ...prev,
                content: file,
            })
        })
    }
    
    function toggleCategory(value){
        setProductInfo((prev)=>{
            return({
                ...prev,
                category: value
            })
        })
    }

    function generateProductSlug(productName) {
        // Remove special characters and spaces from the product name
        const cleanedProductName = productName.replace(/[^\w\s]/g, '').replace(/\s+/g, '').toLowerCase();
      
        // Get the current date in numeric form (month/year)
        const currentDate = new Date();
        const month = String(currentDate.getMonth() + 1);
        const year = String(currentDate.getFullYear()).substr(-2);
        const dateRepresentation = `${month}${year}`;
      
        // Generate a random 4-digit number
        const randomNumber = String(Math.floor(100 + Math.random() * 1000));
      
        // Combine the cleaned product name, current date, and random number to create the slug
        const productSlug = `${cleanedProductName}-${randomNumber}${dateRepresentation}`;
        console.clear()
        return productSlug;
    }

    const navigate = useNavigate()
    
    async function handleSubmit(e){
        e.preventDefault()
        console.error()
        console.log("Try")
        const data = {
            "title": productInfo.productName,
            "description": productInfo.description,
            "price": productInfo.productPrice,
            "creator": id,
            "thumbnail": productInfo.productThumbnailFile,
            "productImage": productInfo.productGalleryFile,
            "slug": productInfo.productSlug,
            "product_content": productInfo.productContent,
            "category": productInfo.category,
        }

        console.log(data)


        
        
        if(userContextUsed.isLoggedIn){
            if(user.isSeller){
                props.handleLoader(true)
                console.clear()
                // const access = localStorage.getItem("access")
                const refresh = localStorage.getItem('refresh');
                if(refresh){
                try {
                    const data = {
                    "refresh": refresh,
                    }
                    const response = await axios.post(`https://gateway.shoptinga.com/api/auth/re-login/`, 
                    data, 
                    {
                        headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'X-CSRFToken': 'uPI26MUqydFtbtfOjm2eLDK6GVnuigxrMaDKInwA4Yv0FypyDKfN6PKjA2BXwhwN'
                        }
                    }
                    );
                    const newAccess = response.data.access
                    localStorage.setItem("access", newAccess)
                    console.clear()
                    console.log("Got New Access Token")
                    if(newAccess){
                        const newSlug = generateProductSlug(productInfo.productName)
                        console.log(newSlug)
                
                        const formData = new FormData();
                        formData.append("title", productInfo.productName);
                        formData.append("description", productInfo.description);
                        formData.append("price", productInfo.productPrice);
                        formData.append("banned", false);
                        formData.append("category", productInfo.category);
                        // formData.append("creator", id);
                        formData.append("thumbnail", fileData.thumbnail);
                        formData.append("productImage", fileData.gallery);
                        formData.append("slug", newSlug);
                        formData.append("product_content", fileData.content);
                        try {
                            const response = await axios.post(
                                `https://gateway.shoptinga.com/api/products/`,
                                formData,
                                {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                    'Authorization': `JWT ${newAccess}`,
                                    'Accept': 'application/json',
                                  },
                                }
                              );
                              console.log(response.data)
                            console.clear()
                            userContextUsed.functions.handleNotification("Product added successfully", "success")
                            navigate("/dashboard")
                            setAccess(false)
                        } catch (error) {
                            console.error(error);
                            props.handleLoader(false)
                            userContextUsed.functions.handleNotification("Failed to add product", "error")
                        }
                    }
                } catch (error) {
                    console.clear()
                    console.error(error.message);
                    props.handleLoader(false)
                    userContextUsed.functions.handleNotification("Failed to verify user", "error")
                }
            } else{
                console.clear()
                console.log("no-access")
                userContextUsed.functions.handleNotification("Failed to verify user", "error")
                props.handleLoader(false)
            }
        } else{
            userContextUsed.functions.handleNotification("Become a seller to Add products", "info")
            navigate("/become-a-seller")
        }
        }
    }

  return (
    <>
    {notFound===false ? <div className='signup new-product-big'>
            <div className="content" style={access ? {opacity: "1"} : {
                opacity: "0.6",
                pointerEvents: "none !important"
            }}>
                <h2 style={{marginBottom: "20px"}}>New Product</h2>
                <div className="card">
                    <form onSubmit={handleSubmit}>
                        <div className="info">
                            Shoptinga will Recieve a commision of <span>3%</span> for all sales made on the platform.
                        </div>
                        
                        <div className="inpt">
                            <label htmlFor="productName">
                                Title*
                            </label>
                            <input type="text" minLength={4} required id='productName' value={productInfo.productName} onChange={(e)=>{
                                setProductInfo((prev)=>{
                                    return({
                                        ...prev,
                                        productName: e.target.value
                                    })
                                })
                            }} />
                        </div>
                        <div className="inpt">
                            <label htmlFor="productDescription">
                                Description
                            </label>
                            <input type="text" minLength={4} required id='productDescription' value={productInfo.description} onChange={(e)=>{
                                setProductInfo((prev)=>{
                                    return({
                                        ...prev,
                                        description: e.target.value
                                    })
                                })
                            }} />
                        </div>
                        <div className="inpt">
                            <label htmlFor="productPrice">
                                Price (₦NGN)
                            </label>
                            <input style={{width: "fit-content"}} type="number" required id='productPrice' value={productInfo.productPrice} onChange={(e)=>{
                                setProductInfo((prev)=>{
                                    return({
                                        ...prev,
                                        productPrice: e.target.value
                                    })
                                })
                            }} />
                        </div>
                        <div className="category">
                            <label>Category ~</label>
                            <div className="select">
                                <div className={productInfo.category === "eBooks" ? "active" : ""} onClick={()=>{
                                    toggleCategory("eBooks")
                                }}>EBook</div>
                                <div className={productInfo.category === "Softwares" ? "active" : ""} onClick={()=>{
                                    toggleCategory("Softwares")
                                }}>Software</div>
                                <div className={productInfo.category === "Songs" ? "active" : ""} onClick={()=>{
                                    toggleCategory("Songs")
                                }}>Song</div>
                                <div className={productInfo.category === "Videos" ? "active" : ""} onClick={()=>{
                                    toggleCategory("Videos")
                                }}>Video</div>
                                <div className={productInfo.category === "Courses" ? "active" : ""} onClick={()=>{
                                    toggleCategory("Courses")
                                }}>Course</div>
                                
                            </div>
                        </div>
                        <div className="image-holder">
                            <div className="image">
                            {productInfo.productThumbnailFile.length>1 ? <div className="remove" onClick={removeImgThumbnail}>
                                <FaTimes />
                            </div> : <div className="remove" style={{display: "none"}}>
                            </div>}
                            {productInfo.productThumbnailFile.length < 1 && <div className="add">
                                <FaPlus />
                            </div>}
                            <label htmlFor="imgFile">
                                <img src={productInfo.productThumbnailFile.length > 0 ? productInfo.productThumbnailFile : "./no-product.png"} className="image" alt='imgFile'/>
                            </label>
                            <input accept="image/*" onChange={handleImageChangeThumbnail} type="file" id='imgFile' name='imgFile'/>
                            </div>
                            <div className="image">
                            {productInfo.productGalleryFile.length>1 ? <div className="remove" onClick={removeImgProductImage}>
                                <FaTimes />
                            </div> : <div className="remove" style={{display: "none"}}>
                            </div>}
                            {productInfo.productGalleryFile.length < 1 && <div className="add">
                                <FaPlus />
                            </div>}
                            <label htmlFor="imgFileX">
                                <img src={productInfo.productGalleryFile.length > 0 ? productInfo.productGalleryFile : "./no-product.png"} className="image" alt='imgFile'/>
                            </label>
                            <input accept="image/*" onChange={handleImageChangeProductImage} type="file" name='imgFileX' id='imgFileX'/>
                            </div>
                        </div>
                        <div className="inpt content-y" style={{
                            margin: "20px 0"
                        }}>
                            <label htmlFor="content">
                                Product Content <i style={{
                                    color: "#222",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    marginLeft: "10px"
                                }}>{"(what you wish to sell ~ e.g an image, a song, a file, a zipped folder containing files etc)"}</i>
                            </label>
                            <input required onChange={uploadContent} type="file" name='content' id='content'/>
                        </div>
                        <button>
                            Add Product <HiSparkles style={{marginLeft: "10px"}}/>
                        </button>
                    </form>
                </div>
            </div>
        </div> : <OopsX />}
    </>
  )
}

export default NewProduct
