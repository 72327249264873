import axios from 'axios';
import { useEffect, useLayoutEffect, useReducer, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import BigImg from './components/bigImg/BigImg';
import Header from './components/header/Header';
import MiniLoader from './components/loader/MiniLoader';
import Preloader from './components/loader/Preloader';
import Menu from './components/menu/Menu';
import Nav from './components/nav/Nav';
import Seller from './components/seller/Seller';
import Sidebar from './components/sidebar/Sidebar';
import BecomeSellerToView from './components/unavailable/BecomeSellerToView';
import LoginToView from './components/unavailable/LoginToView';
import UserContext from './frontendTestContext/UserContext';
import "./index.scss";
import Account from './pages/account/Account';
import EditAccount from './pages/account/EditAcount';
import Login from './pages/auth/Login';
import Reset from './pages/auth/Reset';
import Signup from './pages/auth/Signup';
import Cart from './pages/cart/Cart';
import Dashboard from './pages/dashboard/Dashboard';
import Home from './pages/home/Home';
import NewProduct from './pages/new-product/NewProduct';
import Oops from './pages/oops/Oops';
import Product from './pages/product/Product';
import Search from './pages/search/Search';
import BecomeASeller from './pages/seller/BecomeASeller';
import Shop from './pages/shop/Shop';
// import EditProduct from './pages/edit-product/EditProduct';
// import DeleteProduct from './pages/delete-product/DeleteProduct';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmLogout from './components/confirmation/ConfirmLogout';
import ConfirmPayment from './components/confirmation/ConfirmPayment';
import NoNetwork from './components/noNetwork/NoNetwork';
import OopsX from './pages/oops/OopsX';
import TermsOfService from './pages/termsOfService/TermsOfService';

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const [showMenu, setShowMenu] = useState(false)
  const [showbigImg, setShowBigImg] = useState(false)
  const [previewSeller, setPreviewSeller] = useState(false)
  const [bigImgUrl, setBigImgUrl] = useState("./image-not-found.png")
  const [search, setSearch] = useState("")
  const [miniLoading, setMiniLoading] = useState(false)
  const [maxLoading, setMaxLoading] = useState(false)
  const [maxMaxLoading, setMaxMaxLoading] = useState(false)
  const [handleError, setHandleError] = useState({
    isError: false,
    errorDetails: {
      message: "...",
      status: "...",
      requestType: "",
      endpoint: ""
    }
  })
  const [fetchData, setFetchData] = useState({
    fetchingProducts: false,
    fetchingUser: false,
    fetchingCreators: false,
  })
  const [confirmPayment, setConfirmPayment] = useState({showMe: false, value: null})
  const [confirmLogout, setConfirmLogout] = useState(false)
  // const [confirmPayment, setConfirmPayment] = useState(true)
  const [sellerToView, setSellerToView] = useState("")
  const [category, setCategory] = useState("default")
  const navigate = useNavigate()
  const avatarImages=[
    "signup",
    "bear",
    "boy",
    "cat",
    "empathy",
    "gamer",
    "man",
    "panda",
  ]

  const notificationHandling =  (message, type)=>{
    
    if(type==="success"){
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if(type==="error"){
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if(type==="warn"){
      toast.warn(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if(type==="info"){
      toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  
  const initialState = {
    isLoggedIn: false,
    user: {
      isSeller: false,
      isBanned: false,
      isSuspended: false,
      isDisabled: false,
      isAwaitingDeletion: false,
      userId : "",
      userImg: `./${avatarImages[0]}.png`,
      userName: "",
      userAvatar: 0,
      firstName: "",
      surName: "",
      userEmail : "",
      userPhone: "",
      purchases: [],
      likes: [],
      sellerInfo: {
        info: {
          totalEarnings: "",
          cryptoWalletId: "",
          cryptoType: "",
          bankAccount: "",
          bankName: "",
          commision: "",
          totalSales: "....",
          productsCreated: "....",
          bio: ""
        },
        productsCreated: [
        ]
      }
    },
    shop: {
      products: []
    },
    cart: {
      items: [
      ]
    },
    sellers: [

    ],
    functions: {
      handleNotification: (message, type)=>{
        notificationHandling(message, type)
      },
      setSellerToView: (id)=>{
        setSellerToView(id)
      },
      handleCategory: (categoryx)=>{
        setCategory(categoryx)
      },
      confirmPayment: (value)=>{
        setConfirmPayment({
          showMe: true,
          value: value
        })
      },
      closePayment: ()=>{
        setConfirmPayment({
          showMe: false,
          value: null
        })
      },
      confirmLogout: ()=>{
        setConfirmLogout(true)
      },
      closeLogout: ()=>{
        setConfirmLogout(false)
      }
    }
  }

  const fetchUserData = async () => {
    const refresh = localStorage.getItem('refresh');
    if(refresh && !fetchData.fetchingUser){
      setFetchData((prev)=>{
        return({
          ...prev,
          fetchingUser: true,
        })
      })
      try {
        const data = {
          "refresh": refresh,
        }
        const response = await axios.post(`https://gateway.shoptinga.com/api/auth/re-login/`, 
          data, 
          {
            headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRFToken': 'uPI26MUqydFtbtfOjm2eLDK6GVnuigxrMaDKInwA4Yv0FypyDKfN6PKjA2BXwhwN'
            }
          }
        );
        const newAccess = response.data.access
        localStorage.setItem("access", newAccess)
        // console.log("Got New Access Token")
        if(newAccess){
          try {
            const response = await axios.get(`https://gateway.shoptinga.com/api/my-profile`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${newAccess}`,
                'Accept': 'application/json',
              },
            });
            const userData = response.data;
            dispatch({type: "loginVerified", payload: userData})
            setMaxLoading(false)
            setFetchData((prev)=>{
              return({
                ...prev,
                fetchingUser: false,
              })
            })
            // console.log("User Loaded")
          } catch (error) {
            console.error(error.message);
            dispatch({type: "loginNotVerified"})
            setMaxLoading(false)
            setFetchData((prev)=>{
              return({
                ...prev,
                fetchingUser: false,
              })
            })
          }
        }
      } catch (error) {
        console.error(error.message);
        dispatch({type: "loginNotVerified"})
        setMaxLoading(false)
        setFetchData((prev)=>{
          return({
            ...prev,
            fetchingUser: false,
          })
        })
      }
    } else{
      setMaxLoading(false)
    }
  };

  const fetchSellers = async () => {
    const url = 'https://gateway.shoptinga.com/api/creators/';
    const headers = {
      'accept': 'application/json',
      'X-CSRFToken': 'tPnToQfekkB21L3UpWsF7MDg788BvfmcLaiB0rRoQ5rzvQdEJkFesYDt1fm4Jgly'
    };
    if(!fetchData.fetchingCreators){
      setFetchData((prev)=>{
        return({
          ...prev,
          fetchingCreators: true,
        })
      })
      try {
        const response = await axios.get(url, { headers });
        const sellers = response.data
        const editedSellers = sellers.map((seller)=>{
          return ({
            avatar: seller.avatar,
            username: seller.user.username,
            bio: "",
            id: seller.user.id
          })
        })
        dispatch({type: "fetchedSellers", payload: editedSellers})
        setFetchData((prev)=>{
          return({
            ...prev,
            fetchingCreators: false,
          })
        })
        setFetchData((prev)=>{
          return({
            ...prev,
            fetchingCreators: false,
          })
        })
      } catch (error) {
        console.error(error);
        setFetchData((prev)=>{
          return({
            ...prev,
            fetchingCreators: false,
          })
        })
      }
    }
  }

  const fetchUserDataX = async () => {
    const access = localStorage.getItem('access');

    if( access && !fetchData.fetchingUser){
      setFetchData((prev)=>{
        return({
          ...prev,
          fetchingUser: true,
        })
      })
      try {
        const response = await axios.get(`https://gateway.shoptinga.com/api/my-profile`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${access}`,
            'Accept': 'application/json',
          },
        });
        const userData = response.data;
        setMaxLoading(false)
        dispatch({type: "loginVerified", payload: userData})
        setFetchData((prev)=>{
          return({
            ...prev,
            fetchingUser: false,
          })
        })
      } catch (error) {
        console.error(error.message);
        dispatch({type: "loginNotConfirmed"})
        setMaxLoading(false)
        setFetchData((prev)=>{
          return({
            ...prev,
            fetchingUser: false,
          })
        })
      }
    } else{
      setMaxLoading(false)
    }
  };

  const fetchProducts = async () => {
    if(!fetchData.fetchingProducts){
      setFetchData((prev)=>{
        return({
          ...prev,
          fetchingProducts: true,
        })
      })
      const timeout = setTimeout(()=>{
        initialState.functions.handleNotification("Slow network detected", "warn")
        setMiniLoading(false)
        setIsLoading(false)
      }, 6000)
      try {
        const response = await axios.get('https://gateway.shoptinga.com/api/products/');
        const productList = response.data;
        // console.clear()
        const filterBanned = productList.filter((product)=>{
          return (product.banned === false)
        })
        const productListX = filterBanned.reverse()
        const customizedProductList = productListX.map((product)=>{
          return (
            {
              productDetailsFromBackend:{
                productId: product.id,
                heartCount: product.heart_count,
                productName: product.title,
                productHeadline: product.description,
                productDateCreated: "2nd June 2023",
                productDateLastUpdated: "2nd June 2023",
                productCategory: product.category,
                productIsTrending: true,
                productLikes: product.heart_count,
                createdAt: product.created_at,
                productUrls:{
                  productImgUrl: product.thumbnail,
                  productUrl: product.slug,
                  liveSite: "https://tertiux-linktree.netlify.app",
                  gitHub: "https://github.com/tertiux/tertiux",
                },
                productOwner: {
                  userName: "Creator",
                  userId: product.creator
                },
                productContent: product.product_content,
                productMedia: {
                  images: [
                    {
                      imageUrl: product.productImage,
                      imageName: "image2"
                    },
                  ],
                },
                productFull: {
                  productAbout: product.description,
                  productHighlights: [
                    // {
                    //   highlightName: "Download reusable website templates",
                    // },
                  ]
                },
                productPriceInDollar: product.price,
                hasBeenPurchasedByThisUser: false,
              },
              productDetailsFromBrowser:{
                hasBeenAddedToCart: false,
              }
            }
          )
        })
  
        dispatch({ type: 'fetch-products-success', payload: customizedProductList });
        setMiniLoading(false)
        setIsLoading(false)
        setFetchData((prev)=>{
          return({
            ...prev,
            fetchingProducts: false,
          })
        })
        clearTimeout(timeout)
      } catch (error) {
        // console.log("An error Occured")
        initialState.functions.handleNotification("Failed to Refresh Products", "warn")
        setHandleError({
          isError: true,
          errorDetails: {
            message: error.message,
            priorityMessage: "Network Error",
            requestType: "GET",
          }
        })
        setMiniLoading(false)
        setIsLoading(false)
        setFetchData((prev)=>{
          return({
            ...prev,
            fetchingProducts: false,
          })
        })
        setTimeout(()=>{
          console.log("Error details:", handleError)
        }, 1500)
        clearTimeout(timeout)
      }
    }
  };

  const updateAppState = (state, action)=>{
    let prevList
    
    switch (action.type){
      
      case ("addToCart"):
        prevList = state.cart.items.filter((item)=>{
          return item.itemId !== action.target
        })
        state.functions.handleNotification(`Item has been added to cart`, "success")
        return {
          ...state,
          cart: {
            items: [
              ...prevList,
              {
                itemId: action.target
              }
            ]
          }
        }
        
        case ("removeFromCart"):
        state.functions.handleNotification(`Item has been removed from cart`, "success")
        const newCart = state.cart.items.filter((item)=>{
          return item.itemId !== action.target
        })
        return {
          ...state,
          cart: {
            items: [
              ...newCart
            ]
          }
        }
        
        case ("updateCart"):
          const cartItemsToSave = action.payload
          return {
            ...state,
            cart: {
              items: [
                ...cartItemsToSave
              ]
            }
          }

      case ("loaded"):
        fetchProducts()
        return {
          ...state
        }

      case ("fetch-sellers"):
        fetchSellers()
        return {
          ...state
        }

      case ("fetchedSellers"):
        return {
          ...state,
          sellers: [
            ...action.payload
          ]
        }

      case ("getuser"):
        fetchUserData()
        return {
          ...state
        }
      
      case ("fetch-products-success"):
        const productList = action.payload
        return {
          ...state,
          shop: {
            ...state.shop,
            products: productList,
          }
        }

      case ("loginVerified"):
        const userData = action.payload
        const thisUser = userData[0]
        // console.log(thisUser)
        const sellerProducts = state.shop.products.map((item)=>{
            return(
              {
                productId: item.productDetailsFromBackend.productId,
                productThumbnail: item.productDetailsFromBackend.productUrls.productImgUrl,
                productName: item.productDetailsFromBackend.productName,
                productUrl: item.productDetailsFromBackend.productUrls.productUrl,
                productOwner: item.productDetailsFromBackend.productOwner.userId,
                productPrice: item.productDetailsFromBackend.productPriceInDollar
              }
            )
          })

        return {
          ...state,
          isLoggedIn: true,
          user: {
            isSeller: thisUser.is_seller,
            isBanned: false,
            isSuspended: false,
            isDisabled: false,
            isAwaitingDeletion: false,
            userId : thisUser.id,
            // profileId: thisUser.profile.id,
            userAvatar: thisUser.avatar,
            userImg: `https://subitenary2k23.netlify.app/${avatarImages[thisUser.avatar]}.png`,
            userName: thisUser.user.username,
            firstName: "",
            surName: "",
            userEmail : thisUser.user.email,
            userPhone: "",
            purchases: [
              ...thisUser.purchased_products
            ],
            likes: [],
            sellerInfo: {
              info: {
                totalEarnings: thisUser.account_balance,
                cryptoWalletId: thisUser.crypto_wallet_id,
                cryptoType: thisUser.crypto_type,
                bankAccount: thisUser.bank_account,
                bankName: thisUser.bank_name,
                commision: thisUser.commision,
                totalSales: 300,
                productsCreated: 52,
                bio: thisUser.bio
              },
              productsCreated: [
                ...sellerProducts
              ]
            }
          },
        }
      
        case ("loginNotVerified"):
        return {
          ...state,
          isLoggedIn: false,
          user: {
            ...state.user,
            isSeller: false,
            isBanned: false,
            isSuspended: false,
            isDisabled: false,
            isAwaitingDeletion: false,
            userId : "......",
            userImg: ".......",
            userName: ".......",
            firstName: "...",
            surName: "....",
            userEmail : "...",
            userPhone: "....",
            userAvatar: 0,
            purchases: [
            ],
            sellerInfo: {
              info: {
                totalEarnings: "",
                cryptoWalletId: "",
                cryptoType: "",
                bankAccount: "",
                bankName: "",
                commision: "",
                totalSales: "....",
                productsCreated: "....",
                bio: ""
              },
              productsCreated: [
              ]
            }
          },
        }

        case ("loginNotConfirmed"):
          if(state.isLoggedIn){
            fetchUserData()
          }
          return {
            ...state,
          }
          
        case("confirm-user"):
          if(state.isLoggedIn){
            fetchUserDataX()
          }
          return {
            ...state,
          }
          
        case ("logout"):
          localStorage.removeItem("access")
          localStorage.removeItem("refresh")
          // console.log("Logging Out")
          
          const logout = async () => {
            const url = 'https://gateway.shoptinga.com/api/auth/logout/';
            const headers = {
              'accept': 'application/json',
              'X-CSRFToken': 'uPI26MUqydFtbtfOjm2eLDK6GVnuigxrMaDKInwA4Yv0FypyDKfN6PKjA2BXwhwN',
            };
      
            try {
              await axios.post(url, {}, { headers });
              // console.log("Logout successful");
              appState.functions.handleNotification("Logout successful", "success")
              // Perform any additional actions after successful logout
              setTimeout(()=>{
                setMaxMaxLoading(false)
              }, 2000)
            } catch (error) {
              console.error('Error during logout:', error.message);
              // Handle logout error, if needed
              initialState.functions.handleNotification("Refresh page to reflect changes", "warn")
              setMaxMaxLoading(false)
            }
          };
      
          logout();

          navigate("/")
          return {
            ...state,
            isLoggedIn: false,
            user: {
              isSeller: false,
              isBanned: false,
              isSuspended: false,
              isDisabled: false,
              isAwaitingDeletion: false,
              userId : "......",
              userImg: ".......",
              userName: ".......",
              firstName: "...",
              userAvatar: 0,
              surName: "....",
              userEmail : "...",
              userPhone: "....",
              purchases: [
              ],
              likes: [],
              sellerInfo: {
                info: {
                  totalEarnings: "",
                  cryptoWalletId: "",
                  cryptoType: "",
                  bankAccount: "",
                  bankName: "",
                  commision: "",
                  totalSales: "....",
                  productsCreated: "....",
                  bio: ""
                },
                productsCreated: [
                ]
              }            
            },
          }

      default:
        return state
    }
  }

  const location = useLocation()

  
  const [appState, dispatch] = useReducer(updateAppState, initialState)
  
  useEffect(()=>{
    const fetchThem = ()=>{
    dispatch({type: "loaded"})
    dispatch({type: "fetch-sellers"})
    dispatch({type: "confirm-user"})

  }
  fetchThem()

  },[location.pathname])

  //   if(miniLoading){
  //     const fetchThem = ()=>{
  //       dispatch({type: "loaded"})
  //     }
      
  //     fetchThem()
  //   } else{
  //     const fetchThem = ()=>{
  //       dispatch({type: "loaded"})
  //     }
      
  //     fetchThem()
  //   }
    
  // },[location.pathname, miniLoading])

  useLayoutEffect(()=>{
    setInterval(()=>{
      dispatch({type: "fetch-sellers"})
      dispatch({type: "confirm-user"})
    }, 20000)
  },[])
  
  
  useEffect(()=>{
    if(appState.isLoggedIn === false){
      dispatch({type: "getuser"})
    } else{
      dispatch({type: "confirm-user"})
    }
    if(location.pathname === "/dashboard"){
      dispatch({type: "loaded"})
      setTimeout(()=>{
        setMaxMaxLoading(false)
      }, 1000)
    }
  }, [appState.isLoggedIn, location.pathname])

  useEffect(()=>{
    if(appState.shop.products.length > 0){
      setTimeout(()=>{
        const cartData = appState.cart
        window.localStorage.setItem("cart-data", JSON.stringify(cartData.items))
      }, 500)
    }
  }, [appState])
  
  useEffect(()=>{
    const newCartData = window.localStorage.getItem("cart-data")
    const newCartDataParsed = JSON.parse(newCartData)
    if(newCartDataParsed){
      dispatch({type: "updateCart", payload: newCartDataParsed})
    }
    document.addEventListener("contextmenu", (e)=>{
      e.preventDefault()
    })
  }, [])
  
  
  useEffect(()=>{
    const productList = appState.shop.products;
    if(appState.shop.products.length > 0){
      const cartData = appState.cart
      const productsInCartX = productList.map((product) => {
          const hasBeenAddedToCart = cartData.items.some(
          (item) => item.itemId === product.productDetailsFromBackend.productId);
              return {
                  ...product,
                  productDetailsFromBrowser: {
                  ...product.productDetailsFromBrowser,
                  hasBeenAddedToCart: hasBeenAddedToCart,
              },
          };
      });
  
      const productsInCart = productsInCartX.filter((item)=>{
        return item.productDetailsFromBrowser.hasBeenAddedToCart === true
      })

      const newCartMini = productsInCart.map((product)=>{
        return ({
            itemId: product.productDetailsFromBackend.productId
        })
      })

      setTimeout(()=>{
          const newCartMiniParsed = JSON.stringify(newCartMini)
          localStorage.setItem("cart-data", newCartMiniParsed)
      }, 2000)
    }
  },[appState.cart, appState.shop])
  
  useEffect(()=>{
    if(previewSeller && location.pathname.includes("product") !== true){
      setPreviewSeller(false)
    }
  }, [location.pathname, previewSeller])
  
  useEffect(()=>{
    const pathname = location.pathname
    if(pathname.includes("account/edit")){
      document.title = `Edit Account ~ Shoptinga: The operating system for you and your products`
    } else if(pathname.includes("account")){
      document.title = `Account ~ Shoptinga: The operating system for you and your products`
    } else if(pathname.includes("become-a-seller")){
      document.title = `Become a Seller ~ Shoptinga: The operating system for you and your products`
    } else if(pathname.includes("login")){
      document.title = `Login ~ Shoptinga: The operating system for you and your products`
    } else if(pathname.includes("signup")){
      document.title = `Signup ~ Shoptinga: The operating system for you and your products`
    } else if(pathname.includes("reset")){
      document.title = `Reset password ~ Shoptinga: The operating system for you and your products`
    } else if(pathname.includes("dashboard")){
      document.title = `Dashboard ~ Shoptinga: The operating system for you and your products`
    } else if(pathname.includes("search")){
      document.title = `Search ~ Shoptinga: The operating system for you and your products`
    } else if(pathname.includes("shop")){
      document.title = `Shop ~ Shoptinga: The operating system for you and your products`
    } else if(pathname.includes("cart")){
      document.title = `Cart ~ Shoptinga: The operating system for you and your products`
    } else if(pathname.includes("product")){
      document.title = `Product ~ Shoptinga: The operating system for you and your products`
    } else {
      document.title = `Shoptinga: The operating system for you and your products`
    }

  }, [location.pathname])
  
  function handleLoader(value, skip){
    setMiniLoading(value)
    
    // if(skip){
    //     setTimeout(()=>{
    //       setMiniLoading(false)
    //     }, [1000])
    // }
  }
  
  function handleMaxLoader(value){
    setMaxLoading(value)
  }

  function handleMaxMaxLoader(value){
    setMaxMaxLoading(value)
  }

  useEffect(() => {
    const allLinks = document.querySelectorAll(".active");

    const handleClick = () => {
      // console.log("active clicked")
      const allDivs = document.querySelectorAll("div");
      const allContent = document.querySelectorAll(".content");
      allDivs.forEach((div) => {
        div.scrollTo({
          top: 0,
          behavior: "smooth" // Optional: Add smooth scrolling animation
        });
      });
      allContent.forEach((div) => {
        div.scrollTo({
          top: 0,
          behavior: "smooth" // Optional: Add smooth scrolling animation
        });
      });
    };
  
    allLinks.forEach((link) => {
      link.addEventListener("click", handleClick);
    });
  }, [location.pathname]);
  
  function logOut(){
    dispatch({type: "logout"})
  }
  
  async function checkOut(productids){
    console.clear()
    setMaxLoading(true)
    const access = localStorage.getItem("access")
    const producttoBuy = [productids]
    if(access && appState.isLoggedIn){
      try{
        const response = await axios.post(`https://gateway.shoptinga.com/api/pay/create-checkout-session`, {product_ids: [...producttoBuy]}, {headers: {
          'Content-Type': `application/json`,
          'Authorization': `JWT ${access}`,
          'Accept': 'application/json',
        },});
        // console.log(response.data)
        if (response.data.URL) {
          window.location.href = response.data.URL;
          setMaxLoading(false)
          initialState.functions.handleNotification("Purchase Initialized Successfully", "success")
        } else {
          initialState.functions.handleNotification("Failed to recieve Checkout details", "error")
          setMaxLoading(false)
        }
        // navigate("/dashboard")
      } catch(error){
        console.error(error)
        setMaxLoading(false)
        initialState.functions.handleNotification("Failed to initialize your Purchase", "error")
      }
    } else{
      setTimeout(()=>{
        setMaxMaxLoading(false)
      }, 2000)
      setTimeout(()=>{
        setMaxMaxLoading(false)
        navigate("/login")
        initialState.functions.handleNotification("SignIn to your Account to make purchases 👍", "warn")
      }, 1700)
    }
  }

  async function buyNow(productId){
    console.clear()
    // console.log("id:", productId)
    const access = localStorage.getItem("access")
    const url = `https://gateway.shoptinga.com/api/create-checkout-session`
    setMaxMaxLoading(true)
    if(access && appState.isLoggedIn){
      try{
        const response = await axios.post(url, {
          product_ids: [productId]
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${access}`,
            'Accept': 'application/json',
          }
        })
         console.log(response.data)
        initialState.functions.handleNotification("Initializing your Purchase ...", "info")
        setMaxMaxLoading(false)
      } catch(error){
        console.error(error)
        initialState.functions.handleNotification("Failed to initialize your purchase", "error")
        setMaxMaxLoading(false)
      }
    } else{
      setTimeout(()=>{
        setMaxMaxLoading(false)
      }, 2000)
      setTimeout(()=>{
        setMaxMaxLoading(false)
        navigate("/login")
        initialState.functions.handleNotification("SignIn to your Account to make purchases 👍", "warn")
      }, 1700)
    }
  }

  function downloadNow(productName, productUrl) {
    setMaxMaxLoading(true)
    try {
      console.clear();
      // console.log("Downloading:", productName);
  
      const sanitizedProductName = productName
        .replace(/[^\w\s.-]/gi, "")
        .replace(/\s+/g, "-");
  
      const downloadLink = document.createElement("a");
      downloadLink.href = productUrl;
      downloadLink.download = `${sanitizedProductName}.file`;
      setTimeout(()=>{
        downloadLink.click();
      }, 1000)
  
      // console.log("Download complete:", `${sanitizedProductName}.file`);
      initialState.functions.handleNotification("Downloading", "info")
      setTimeout(()=>{
        setMaxMaxLoading(true)
      }, 2000)
    } catch (error) {
      console.error("Error downloading:", error);
      initialState.functions.handleNotification("Failed to download File", "error")
      setTimeout(()=>{
        setMaxMaxLoading(false)
      }, 500)
    }
  }

  function likeThis(id){
    // console.log("Like This:", id)
    setTimeout(()=>{
      handleLoader(false)
    }, 1000)
  }
  
  function unLikeThis(id){
    // console.log("Unlike This:", id)
    setTimeout(()=>{
      handleLoader(false)
    }, 1000)
  }
  
  
  return (
    <UserContext.Provider value={appState}>
        <div className={`app ${previewSeller && "preview-seller"} ${miniLoading && "mini-loading"} ${maxMaxLoading && "mini-loading"}  ${maxLoading && "mini-loading"} ${isLoading && "loading"} ${showMenu && "show-menu"} ${confirmPayment.showMe && "confirming-payment"} ${confirmLogout && "confirming-logout"}`}>

          <Header search={search} setSearch={setSearch} handleLoader={handleLoader}></Header>

          <Sidebar logOut={logOut} handleLoader={handleLoader}/>
          
          <Nav showMenu={()=>{
            setShowMenu(true)
          }} handleLoader={handleLoader}></Nav>
          
          <Menu logOut={logOut} hideMenu ={()=>{
            setShowMenu(false)
          }} handleLoader={handleLoader}></Menu>
          
          <BigImg bigImgUrl={bigImgUrl} showbigImg={showbigImg} hideBigImg={()=>{
            setShowBigImg(false)
            setTimeout(()=>{
              setBigImgUrl("./image-not-found.png")
            }, 600)
          }}/>
          
          <div onClick={()=>{
            setShowMenu(false)
            initialState.functions.closePayment()
            initialState.functions.closeLogout()
          }} className='backdrop'></div>
          
          <Preloader></Preloader>
          
          <MiniLoader />
          
          <Seller handleLoader={handleLoader} sellerToView={sellerToView} previewSeller={previewSeller} closeSellerPreview={()=>{
            setPreviewSeller(false)
          }}/>
          
          <BecomeSellerToView />
          <LoginToView />
          <NoNetwork />
          <ConfirmPayment removeFromCart={(productId)=>{
              dispatch({type: "removeFromCart", target: productId})
            }} handleMaxLoader={handleMaxLoader} handleLoader={handleLoader} />
            
          <ConfirmLogout logOut={logOut}/>

          {/* NOtification handling */}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            limit={10}
          />
          
          
          
          <Routes>

            <Route path='/' element={<Home handleLoader={handleLoader} addToCart={(productId)=>{
              dispatch({type: "addToCart", target: productId})
            }} removeFromCart={(productId)=>{
              dispatch({type: "removeFromCart", target: productId})
            }} buyNow={buyNow} likeProduct={likeThis} unLikeProduct={unLikeThis} />} />

            <Route path='/home' element={<Home handleLoader={handleLoader} addToCart={(productId)=>{
              dispatch({type: "addToCart", target: productId})
            }} removeFromCart={(productId)=>{
              dispatch({type: "removeFromCart", target: productId})
            }} buyNow={buyNow} handleCategory={(categoryx)=>{
              setCategory(categoryx)
            }} likeProduct={likeThis} unLikeProduct={unLikeThis} />} />

            <Route path='/shop' element={<Shop addToCart={(productId)=>{
              dispatch({type: "addToCart", target: productId})
            }} removeFromCart={(productId)=>{
              dispatch({type: "removeFromCart", target: productId})
            }} handleLoader={handleLoader} buyNow={buyNow} category={category} handleCategory={(categoryx)=>{
              setCategory(categoryx)
            }} likeProduct={likeThis} unLikeProduct={unLikeThis} />} />

            <Route path='/dashboard' element={appState.isLoggedIn ? <Dashboard handleLoader={handleLoader}/> : <OopsX />} />

            <Route path='/search' element={<Search search={search} setSearch={setSearch} addToCart={(productId)=>{
              dispatch({type: "addToCart", target: productId})
            }} removeFromCart={(productId)=>{
              dispatch({type: "removeFromCart", target: productId})
            }} handleLoader={handleLoader}  buyNow={buyNow} category={category} handleCategory={(categoryx)=>{
              setCategory(categoryx)
            }} likeProduct={likeThis} unLikeProduct={unLikeThis} />} />

            <Route path='/cart' element={<Cart  removeFromCart={(productId)=>{
              dispatch({type: "removeFromCart", target: productId})
            }} handleMaxLoader={handleMaxLoader} handleLoader={handleLoader} />} />

            <Route path='/product/:productId' element={<Product showbigImg={(url)=>{
              setShowBigImg(true)
              setBigImgUrl(url)
            }} addToCart={(productId)=>{
              dispatch({type: "addToCart", target: productId})
            }} removeFromCart={(productId)=>{
              dispatch({type: "removeFromCart", target: productId})
            }} previewSeller={()=>{
              setPreviewSeller(true)
            }
            } sellerToView={sellerToView} buyNow={buyNow} downloadNow={downloadNow} checkOut={checkOut} likeProduct={likeThis} unLikeProduct={unLikeThis} handleLoader={handleLoader} />} />

            <Route path='/account' element={appState.isLoggedIn ? <Account handleLoader={handleLoader}/> : <OopsX />} />
            
            <Route path='/account/edit' element={appState.isLoggedIn ? <EditAccount handleLoader={handleMaxLoader} updateUser={()=>{
              fetchUserDataX()
            }}/> : <OopsX />} />
            
            <Route path='/login' element={<Login  logIn={()=>{dispatch({type: "getuser"})}} logOut={()=>{dispatch({type: "logout"})}} handleLoader={handleMaxLoader}  handleMinLoader={handleLoader} />} />
            
            <Route path='/signup' element={<Signup handleLoader={handleMaxLoader}  handleMinLoader={handleLoader}/>} />

            <Route path='/become-a-seller' element={appState.isLoggedIn ? <BecomeASeller handleLoader={handleMaxLoader} updateUser={()=>{
              fetchUserDataX()
            }}/> : <OopsX />} />
            
            <Route path='/reset' element={<Reset  reset={()=>{dispatch({type: "logout"})}} handleLoader={handleLoader} />} />

            <Route path='/dashboard/new-product' element={appState.isLoggedIn ? <NewProduct handleLoader={handleMaxMaxLoader} /> : <OopsX />} />

            <Route path='/dashboard/create-product' element={appState.isLoggedIn ? <NewProduct handleLoader={handleMaxMaxLoader} /> : <OopsX />} />
            
            {/* <Route path='/dashboard/edit-product/:productId' element={appState.isLoggedIn ? <EditProduct handleLoader={handleMaxMaxLoader} /> : <OopsX />} /> */}

            {/* <Route path='/dashboard/delete-product/:productId' element={appState.isLoggedIn ? <DeleteProduct handleLoader={handleMaxMaxLoader} /> : <OopsX />} /> */}
            
            {/* <Route path='/settings' element={<Settings />} /> */}
            
            <Route path='/terms-of-service' element={<TermsOfService />} />
            
            <Route path='/*' element={<Oops dispatch={dispatch} />} />
          </Routes>
        </div>
    </UserContext.Provider>
  );
}

export default App;
