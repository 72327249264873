import React, { useContext, useState } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import UserContext from '../../frontendTestContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { usePaystackPayment } from 'react-paystack';
import { CoinbaseCommerceButton } from '@iofate/react-coinbase-commerce';

const ConfirmPayment = (props) => {
  const navigate = useNavigate();
  const userContextUsed = useContext(UserContext);
  const user = userContextUsed.user;
  const cartData = userContextUsed.cart;
  const shopData = userContextUsed.shop;
  const [charge, setCharge] = useState(null);
  const access = localStorage.getItem("access");
  // const [price_in_naira, setPrice_in_naira] = useState(null);

  const productList = shopData.products;
  const productsInCartX = productList.map((product) => {
    const hasBeenAddedToCart = cartData.items.some(
      (item) => item.itemId === product.productDetailsFromBackend.productId
    );

    return {
      ...product,
      productDetailsFromBrowser: {
        ...product.productDetailsFromBrowser,
        hasBeenAddedToCart: hasBeenAddedToCart,
      },
    };
  });

  const productsInCart = productsInCartX.filter((item) => {
    return item.productDetailsFromBrowser.hasBeenAddedToCart === true;
  });

  const totalPrice = productsInCart.reduce((acc, item) => {
    const priceFloat = parseFloat(item.productDetailsFromBackend.productPriceInDollar);
    return acc + priceFloat;
  }, 0).toFixed(2);

  // Function to fetch data from the API and perform the conversion
  // async function convertUSDToNGN(amount) {
  //   try {
  //     const response = await axios.get('https://v6.exchangerate-api.com/v6/ba59cd2808cf46af8e96f8c5/latest/USD');

  //     if (response.data.result === "error") {
  //       userContextUsed.functions.handleNotification("Unavailable right now", "error");
  //       return;
  //     } else {
  //       const conversionRates = response.data.conversion_rates.NGN;
  //       const result = (amount * conversionRates) * 100;
  //       const roundedNumber = Math.round(result);

  //       setPrice_in_naira(roundedNumber);
  //       return;
  //     }
  //   } catch (error) {
  //     return;
  //   }
  // }

  const doThis = () => {
    setTimeout(() => {
      props.handleMaxLoader(false);
    }, 2000);
    setTimeout(() => {
      navigate("/login");
      userContextUsed.functions.handleNotification("Sign in to your Account to make purchases 👍", "warn");
    }, 1700);
  };

  const onSuccess = (reference) => {
    userContextUsed.functions.handleNotification("Payment successful 👍", "success");
  };

  const onClose = () => {
    userContextUsed.functions.handleNotification("Payment Failed", "error");
  };

  async function checkout() {
    props.handleMaxLoader(true);
    const cartIdsToCheckOut = productsInCart.map((item) => {
      return item.productDetailsFromBackend.productId;
    });

    if (access && userContextUsed.isLoggedIn) {
      try {
        const response = await axios.post('https://shoptinga.onrender.com/api/pay/create-checkout-session', {
          product_ids: cartIdsToCheckOut,
        }, {
          headers: {
            'Content-Type': `application/json`,
            'Authorization': `JWT ${access}`,
            'Accept': 'application/json',
          },
        });
        if (response.data) {
          setCharge(response.data.charge);
          props.handleMaxLoader(false);
        } else {
          userContextUsed.functions.handleNotification("Failed to receive Checkout details", "error");
          props.handleMaxLoader(false);
        }
      } catch (error) {
        console.error(error);
        userContextUsed.functions.handleNotification("An error occurred, please try again", "error");
        props.handleMaxLoader(false);
      }
    } else {
      doThis();
    }
  }

  const PaystackHook = () => {
    // convertUSDToNGN(totalPrice);

    const config = {
      reference: (new Date()).getTime().toString(),
      email: user.userEmail,
      amount: parseFloat(totalPrice),
      publicKey: 'pk_live_5e3782a8b0e5251d8db2e86b2e0d9f154a89de50',
    }
    const initializePayment = usePaystackPayment(config);
  
    if (totalPrice === null) return <button> ... </button>;
    // Now Execute
    return (
      <div>
        <button onClick={() => {
          initializePayment(onSuccess, onClose);
        }}> Fiat </button>
      </div>
    );
  };

  return (
    <div className='confirm payment'>
      <div className="container">
        <div className="info">
          Confirm details
        </div>
        <button className="close">
          <FaTimes />
        </button>
        <div className="content">
          <p>
            Make this payment using <b>Crypto</b>
          </p>
          <small style={{ opacity: 0.4 }}> <i>click to continue</i> </small>
        </div>
        <div className="actions">
          <div className="controls">
            {charge ? <CoinbaseCommerceButton checkoutId={charge} /> :
              <>
                <PaystackHook />
                <button onClick={() => {
                  checkout();
                }}>Crypto</button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPayment;
