import React, { useContext, useEffect, useState } from 'react'
import { FaArrowLeft, FaExternalLinkAlt, FaQuestion } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import UserContext from '../../frontendTestContext/UserContext'

const Seller = (props) => {
  const userContextUsed = useContext(UserContext)
  const [sellerInfo, setSellerInfo] = useState({
    username: "",
    bio: "",
    avatar: 0,
    products: [
    ]
  })

  const [showInfo, setShowInfo] = useState(false)

  const avatarImages=[
    "signup",
    "bear",
    "boy",
    "cat",
    "empathy",
    "gamer",
    "man",
    "panda",
  ]

  const previewSeller = props.previewSeller
  const sellers = userContextUsed.sellers
  useEffect(()=>{
    const filteredSellers = sellers.filter((seller) => {
      return String(seller.id) === String(props.sellerToView);
    });
    const thisSeller = filteredSellers[0]
    if(thisSeller){
      const thisSellersProducts = userContextUsed.shop.products.filter((product)=>{
        return (String(product.productDetailsFromBackend.productOwner.userId) === String(props.sellerToView))
      })
      const thisSellersProductsEdited = thisSellersProducts.map((product)=>{
        return {
          productName: product.productDetailsFromBackend.productName,
          productSlug: product.productDetailsFromBackend.productUrls.productUrl,
          productImgUrl: product.productDetailsFromBackend.productUrls.productImgUrl,
        }
      })
      setSellerInfo({
        username: thisSeller.username,
        bio: thisSeller.bio,
        avatar: thisSeller.avatar,
        products: [
          ...thisSellersProductsEdited
        ]
      })
    } else{
      setSellerInfo({
        username: "Not Found",
        bio: "",
        avatar: "",
        products: [
        ]
      })
    }
    if(previewSeller){
      setTimeout(()=>{
        setShowInfo(true)
      }, 1500)
      console.clear()
      console.log(props.sellerToView)
    } else{
      setTimeout(()=>{
        setShowInfo(false)
      }, 900)
    }
  }, [previewSeller, sellers, props.sellerToView, userContextUsed])

  const productsEl = sellerInfo.products.map((product)=>{
    return(
      <div className="product">
        <img style={{backgroundColor: "#222"}} src={product.productImgUrl} alt="" />
        <p>
          {product.productName}
        </p>
        <Link onClick={()=>{
          props.closeSellerPreview()
          props.handleLoader(true)
        }} to={`/product/${product.productSlug}`}>
          View <FaExternalLinkAlt />
        </Link>
      </div>
    )
  })

  return (
    <div className='seller'>
      <button className="close" onClick={props.closeSellerPreview}>
        <FaArrowLeft />
      </button>
      {showInfo ? <div className="content">
        <div className="heading">
          {sellerInfo.username.length > 0 ? <img src={`https://subitenary2k23.netlify.app/${avatarImages[sellerInfo.avatar]}.png`} alt="" /> : <p><FaQuestion /></p>}
        </div>
        <div className="first-section">
          <h2>
            {sellerInfo.username}
          </h2>
          {sellerInfo.bio.length>0 && <p>
            {sellerInfo.bio}
          </p>}
        </div>
        {sellerInfo.products.length > 0 && <div className="second-section">
          <h2>
            All Products
          </h2>
          <div className="products">
            {productsEl}
          </div>
        </div>}
      </div> : <div className='content'>
            <div className="loader-holder" style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
              <div className="loader"></div>
            </div>
        </div>}
      <div className="bg" onClick={props.closeSellerPreview}></div>
    </div>
  )
}

export default Seller