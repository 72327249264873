import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import UserContext from '../../frontendTestContext/UserContext'

const Signup = (props) => {
  const userContextUsed = useContext(UserContext)
  const user = userContextUsed.user
  
  const [userInfo, setUserInfo] = useState({
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    cpassword: "",
    tncs: false,
    imgFile: "",
  })

  function handleChange(e){
    const name = e.target.name
    if(name === "tncs"){
      setUserInfo((prev)=>{
        return({
          ...prev,
          [name]: e.target.checked
        })
      })
    } else{
      setUserInfo((prev)=>{
        return({
          ...prev,
          [name]: e.target.value
        })
      })
    }
  }

  const navigate = useNavigate()

  
  async function handleSubmit(e){
    e.preventDefault()

    const username = userInfo.username
    const email = userInfo.email
    const password = userInfo.password
    const cpassword = userInfo.cpassword
    
    if(password === cpassword){
      props.handleLoader(true, false)
      // console.log(username, email, password)
      

      try {
        const response = await axios.post('https://gateway.shoptinga.com/api/users/', {
          username: username,
          email: email,
          password: password,
        });

        console.log(response.data);
        
        if (response.status >= 200 && response.status < 300) {
          // Login successful, perform any necessary actions
          props.handleLoader(false, false)
          // console.log('Signup successful');
          userContextUsed.functions.handleNotification("LogIn successful", "success")
          navigate("/login")
        } else {
          // Login failed, handle the error
          userContextUsed.functions.handleNotification("Oops an error occured", "error")
          props.handleLoader(false, false)
          alert('Signup failed');
        }
        
      } catch (error) {
        console.error(error);
        alert("An error Occured")
        userContextUsed.functions.handleNotification("Oops an error occured", "error")
        props.handleLoader(false, false)
        //props.showError("An Error Occured")
      }
    } else{
      userContextUsed.functions.handleNotification("Passwords do not match", "error")
      props.handleLoader(false, false)
      //props.showError("Passwords do not Match")
    }
  }

  return (
    <div className='signup new-product-big'>
      <div className="content">
        <div className="card">
          <form onSubmit={handleSubmit}>
            <h2>Sign Up</h2>
            {userContextUsed.isLoggedIn && <div className='info'>
                <p>
                  You are already Logged in as <span>{user.userName}</span>.
                </p>
              </div>}
            <div className="inpt">
              <label htmlFor="email">
                Username
              </label>
              <input required name='username' value={userInfo.username} onChange={handleChange} type="name" id='username' minLength={5} maxLength={17} />
            </div>
            <div className="inpt">
              <label htmlFor="email">
                Email
              </label>
              <input required name='email' value={userInfo.email} onChange={handleChange} type="email" id='email' />
            </div>
            <div className="inpt">
              <label htmlFor="password">
                Password
              </label>
              <input required name='password' value={userInfo.password} onChange={handleChange} type="password" id='password' />
            </div>
            <div className="inpt">
              <label htmlFor="cpassword">
                Confirm Password
              </label>
              <input required name='cpassword' value={userInfo.cpassword} onChange={handleChange} type="password" id='cpassword' />
            </div>
            <div className="inpt-check">
              <input required name='tncs' value={userInfo.tncs} onChange={handleChange} type="checkbox" id='tandc' />
              <label htmlFor="tandc">
                I accept the <a href="#!">terms and conditions & privacy policy</a>
              </label>
            </div>
            <button>
              Signup
            </button>
            <p>Already have an account? <Link to={"/login"}>Login</Link> </p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Signup








// import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
// import {FaTimes} from "react-icons/fa"
// import {BiRotateRight} from "react-icons/bi"
// import defaultImage from "../../assets/signup.jpg"

// const Signup = () => {
//   const [userInfo, setUserInfo] = useState({
//     username: "",
//     firstname: "",
//     lastname: "",
//     email: "",
//     password: "",
//     cpassword: "",
//     tncs: false,
//     imgFile: "",
//   })

//   function handleChange(e){
//     const name = e.target.name
//     if(name === "tncs"){
//       setUserInfo((prev)=>{
//         return({
//           ...prev,
//           [name]: e.target.checked
//         })
//       })
//     } else{
//       setUserInfo((prev)=>{
//         return({
//           ...prev,
//           [name]: e.target.value
//         })
//       })
//     }
//   }

//   const removeImg = ()=>{
//     setUserInfo((prev)=>{
//       return({
//         ...prev,
//         imgFile: ""
//       })
//     })
//   }

//   const randomImg = ()=>{
//     const randomImages = [
//       "https://static.vecteezy.com/system/resources/thumbnails/002/202/047/small/blue-high-tech-futuristic-cyberspace-technology-background-free-vector.jpg",
//       "https://image.lexica.art/md2/20f95b33-d96f-4bca-a32e-a66a6a5963f0",
//       "https://image.lexica.art/md2/176584fe-6290-4ec7-9988-156fc4570d8a",
//       "https://image.lexica.art/md2/16f47f65-ffde-49c4-ba08-70dc0937c517",
//       "https://image.lexica.art/md2/aa997782-2061-4798-a888-420008a90cbf",
//       "https://image.lexica.art/md2/528bd1e0-c4fa-4f0e-a784-a537142471e8",
//       "https://image.lexica.art/md2/84f7f1da-01f7-487d-8aff-5d3316dbce3a",
//       "https://image.lexica.art/md2/3e05e2cd-8a3c-410e-9716-6d5f7ec27d62",
//     ]
//     //Get a random image from unsplash
//     const randomNum = Math.floor(Math.random()*randomImages.length)
//     const randomImg = randomImages[randomNum]
//     setUserInfo((prev)=>{
//       return({
//         ...prev,
//         imgFile: randomImg
//       })
//     })
//   }

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     const reader = new FileReader();

//     reader.onload = (event) => {
//     //   setImageSrc(event.target.result);
//       setUserInfo((prev)=>{
//         return({
//             ...prev,
//             imgFile: event.target.result
//         })
//       });
//     };

//     reader.readAsDataURL(file);
//   };

//   function handleSubmit(e){
//     e.preventDefault()
//   }

//   return (
//     <div className='signup'>
//       <div className="content">
//         <div className="card">
//           <form onSubmit={handleSubmit}>
//           <h2>Sign Up</h2>
//             <div className="image">
//               {userInfo.imgFile.length>1 ? <div className="remove" onClick={removeImg}>
//                 <FaTimes />
//               </div> : <div className="remove" onClick={randomImg}>
//                 <BiRotateRight />
//               </div>}
//               <label htmlFor="imgFile">
//                 <img src={userInfo.imgFile.length > 1 ? userInfo.imgFile : defaultImage} className="image" alt='imgFile'/>
//               </label>
//               <input accept="image/*" onChange={handleImageChange} type="file" id='imgFile'/>
//             </div>
//             <div className="inpt">
//               <label htmlFor="userName">
//                 Username
//               </label>
//               <input required name='username' value={userInfo.username} onChange={handleChange} type="text" id='userName' />
//             </div>
//             <div className="inpt">
//               <label htmlFor="firstName">
//                 First Name
//               </label>
//               <input required name='firstname' value={userInfo.firstname} onChange={handleChange} type="text" id='firstName' />
//             </div>
//             <div className="inpt">
//               <label htmlFor="lastName">
//                 Last Name
//               </label>
//               <input required name='lastname' value={userInfo.lastname} onChange={handleChange} type="text" id='lastName' />
//             </div>
//             <div className="inpt">
//               <label htmlFor="email">
//                 Email
//               </label>
//               <input required name='email' value={userInfo.email} onChange={handleChange} type="email" id='email' />
//             </div>
//             <div className="inpt">
//               <label htmlFor="password">
//                 Password
//               </label>
//               <input required name='password' value={userInfo.password} onChange={handleChange} type="password" id='password' />
//             </div>
//             <div className="inpt">
//               <label htmlFor="cpassword">
//                 Confirm Password
//               </label>
//               <input required name='cpassword' value={userInfo.cpassword} onChange={handleChange} type="password" id='cpassword' />
//             </div>
//             <div className="inpt-check">
//               <input required name='tncs' value={userInfo.tncs} onChange={handleChange} type="checkbox" id='tandc' />
//               <label htmlFor="tandc">
//                 I accept the <a href="#!">terms and conditions & privacy policy</a>
//               </label>
//             </div>
//             <button>
//               Signup
//             </button>
//             <p>Already have an account? <Link to={"/login"}>Login</Link> </p>
//           </form>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Signup