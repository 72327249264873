import React, { useState } from 'react'
import { useContext } from 'react'
import { FaChevronDown, FaMinus } from 'react-icons/fa'
import { FiExternalLink } from 'react-icons/fi'
import { BsFillHeartbreakFill } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import UserContext from '../../frontendTestContext/UserContext'
// import axios from 'axios'

function Cart(props) {
    const userContextUsed = useContext(UserContext)
    const shopData = userContextUsed.shop
    const cartData = userContextUsed.cart
    const access = localStorage.getItem("access");
    const navigate = useNavigate();

    const productList = shopData.products;
    const productsInCartX = productList.map((product) => {
        const hasBeenAddedToCart = cartData.items.some(
        (item) => item.itemId === product.productDetailsFromBackend.productId);

            return {
                ...product,
                productDetailsFromBrowser: {
                ...product.productDetailsFromBrowser,
                hasBeenAddedToCart: hasBeenAddedToCart,
            },
        };
    });

    const productsInCart = productsInCartX.filter((item)=>{
        return item.productDetailsFromBrowser.hasBeenAddedToCart === true
    })
    
    const totalPrice = productsInCart.reduce((acc, item) => {
        const priceFloat = parseFloat(item.productDetailsFromBackend.productPriceInDollar);
        return acc + priceFloat;
    }, 0).toFixed(2);
    
    const checkout = ()=>{
        if(access && userContextUsed.isLoggedIn) {
            const cartIdsToCheckOut = productsInCart.map((item)=>{
                return item.productDetailsFromBackend.productId
            })
            userContextUsed.functions.confirmPayment(cartIdsToCheckOut)
        } else {
            setTimeout(()=>{
                props.handleMaxLoader(false)
            }, 2000)
            setTimeout(()=>{
                navigate("/login")
                userContextUsed.functions.handleNotification("SignIn to your Account to make purchases 👍", "warn")
            }, 1700)
        } 
    }

    const cartEl = productsInCart.map((item)=>{
        return (
            <div key={item.productDetailsFromBackend.productId} className="product">
                <div className="top" style={{
                    backgroundImage: `linear-gradient(#0000, #000e), url(${item.productDetailsFromBackend.productUrls.productImgUrl})`,
                }}>
                    <Link to={`/product/${item.productDetailsFromBackend.productUrls.productUrl}`} className='product__link'>
                        <FiExternalLink />
                    </Link>
                    <div className="product-details">
                        <div className="product-name">
                            {item.productDetailsFromBackend.productName}
                        </div>
                        <div className="product-price">
                            ₦{item.productDetailsFromBackend.productPriceInDollar}
                        </div>
                    </div>
                </div>
                <div className="remove" onClick={()=>{
                    props.removeFromCart(item.productDetailsFromBackend.productId)
                }}>
                    <FaMinus />
                </div>
            </div>
        )
    })

    const [hideTemp, setHideTemp] = useState(false)
  return (
    <div className='cart-big'>
      <div className="content">
        <div className="heading">
            <h2>Cart</h2>
        </div>
        <div className={`${hideTemp ? "hideTemp" : ""} ${productsInCart.length > 0 ? "summary" : "summary empty"}`}>
            <div className="holder">
                <div className="title">
                    <h2>SUMMARY</h2>
                </div>
                <div className="controls">
                    <div className="hide-temporary" onClick={()=>{
                        setHideTemp(!hideTemp)
                    }}>
                        <FaChevronDown />
                    </div>
                </div>
                <div className="total">
                    <p>
                        Number of items
                    </p>
                    <p>
                        {productsInCart.length}
                    </p>
                </div>
                <div className="total">
                    <p>
                        Total
                    </p>
                    <p>
                        ₦{parseFloat(totalPrice)}
                    </p>
                </div>
                <div className="checkout">
                    <button onClick={checkout} className='gotoCheckout'>
                        Checkout
                    </button>
                </div>
            </div>
        </div>
        {cartEl.length > 0 ? <div className="container">
            {cartEl}            
        </div> : <div className="container empty">
            <BsFillHeartbreakFill />           
            <p>
                Add Items from the <Link to={"/shop"}>Shop</Link> to your Cart to see them Here
            </p>
        </div>}
      </div>
    </div>
  )
}

export default Cart
