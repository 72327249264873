import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {CgDanger} from "react-icons/cg"

const Reset = () => {
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  })

  function handleChange(e){
    const name = e.target.name
    if(name === "tncs"){
      setUserInfo((prev)=>{
        return({
          ...prev,
          [name]: e.target.checked
        })
      })
    } else{
      setUserInfo((prev)=>{
        return({
          ...prev,
          [name]: e.target.value
        })
      })
    }
  }

  function handleSubmit(e){
    e.preventDefault()
  }

  return (
    <div className='signup reset'>
      <div className="content">
        <div className="card">
          <form onSubmit={handleSubmit}>
          <h2>Reset Password</h2>
            <div className="inpt">
              <label htmlFor="email">
                Email
              </label>
              <input required name='email' value={userInfo.email} onChange={handleChange} type="email" id='email' />
            </div>
            <button className='reset__btn'>
              Reset Password <CgDanger />
            </button>
            <div className="last-boys">
              <p>- Back to <Link to={"/login"}>Login</Link> </p>
              <p>Create a new Account <Link to={"/signup"}>Sign Up</Link> -</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Reset