import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import UserContext from '../../frontendTestContext/UserContext';

const Login = (props) => {
  const userContextUsed = useContext(UserContext);
  const user = userContextUsed.user;
  const navigate = useNavigate();

  useEffect(() => {
    // Store the current URL in session storage before redirecting to login
    sessionStorage.setItem('redirectPath', window.location.pathname);
  }, []);

  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
  });

  function handleChange(e) {
    const name = e.target.name;
    if (name === 'tncs') {
      setUserInfo((prev) => ({
        ...prev,
        [name]: e.target.checked,
      }));
    } else {
      setUserInfo((prev) => ({
        ...prev,
        [name]: e.target.value,
      }));
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const email = userInfo.email;
    const password = userInfo.password;
    props.handleLoader(true);

    try {
      const response = await axios.post(
        'https://gateway.shoptinga.com/api/auth/login/',
        {
          email,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        // Login successful
        localStorage.setItem('access', response.data.access);
        localStorage.setItem('refresh', response.data.refresh);
        props.logIn();
        userContextUsed.functions.handleNotification('LogIn successful', 'success');

        // Retrieve the stored URL from session storage
        const redirectPath = sessionStorage.getItem('redirectPath') || '/';
        // Redirect the user back to the stored URL
        navigate(redirectPath);
      } else {
        // Login failed, handle the error
        userContextUsed.functions.handleNotification('Oops an error occurred', 'error');
        props.handleLoader(false, false);
      }
    } catch (error) {
      // Handle any network or request errors
      props.handleLoader(false, false);
      console.error(error);
      userContextUsed.functions.handleNotification('Oops an error occurred', 'error');
    }
  }

  return (
    <div className='new-product-big signup login'>
      <div className='content'>
        <div className='card'>
          <form onSubmit={handleSubmit}>
            <h2>Login</h2>
            {userContextUsed.isLoggedIn && (
              <div className='info'>
                <p>
                  You are already Logged in as <span>{user.userName}</span>.
                </p>
              </div>
            )}
            <div className='inpt'>
              <label htmlFor='email'>Email</label>
              <input required name='email' value={userInfo.email} onChange={handleChange} type='email' id='email' />
            </div>
            <div className='inpt'>
              <label htmlFor='password'>Password</label>
              <input required name='password' value={userInfo.password} onChange={handleChange} type='password' id='password' />
            </div>
            <button>Login</button>
            <div className='last-boys'>
              <p>
                - Forgot Password? <Link to={'/reset'}>Reset</Link>{' '}
              </p>
              <p>
                Don't have an Account <Link to={'/signup'}>Sign Up</Link> -
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
