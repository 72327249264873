import React, { useEffect, useRef, useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { BsMoon } from 'react-icons/bs'
import { FaArrowRight, FaChevronDown, FaUserPlus } from 'react-icons/fa'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import UserContext from '../../frontendTestContext/UserContext'
import TopBanner from '../top-banner/Top_Banner'

const Header = (props) => {
    const search = props.search
    const setSearch = props.setSearch
    const [hideItems, sethideItems] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()
    const userContextUsed = useContext(UserContext)
    const isLoggedIn = userContextUsed.isLoggedIn
    const user = userContextUsed.user
    // const shopData = userContextUsed.shop
    const cartData = userContextUsed.cart
    const productsInCart = cartData.items

    const searchRef = useRef(null)

    useEffect(()=>{
        if(location.pathname.includes("signup")||location.pathname.includes("login")||location.pathname.includes("reset")||location.pathname.includes("cart")||location.pathname.includes("account")){
            sethideItems(true)
        } else{
            sethideItems(false)
        }
    }, [location.pathname])
  return (
        
    <div className={`header ${hideItems && "hide-items"}`}>
        <TopBanner />
        <div className='sub-header'>
        <form className="search-bar" onSubmit={(e)=>{
            e.preventDefault()
            navigate("/search")
            props.handleLoader(true)
        }}>
            <button style={{
                background: "transparent",
                border: "none",
                outline: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <BsSearch className="search-icon" />
            </button>
            <input ref={searchRef} type="search" value={search} onChange={(e)=>{
                setSearch(e.target.value)
                if(e.target.value.length > 2){
                    navigate("/search")
                }
            }} placeholder="Search Here" />
        </form>
        <div className="links-holder">
            <div className="dark-theme-toggle" style={{display: "none"}}>
                <BsMoon />
            </div>

            <NavLink to={`${(location.pathname.includes("cart")&&productsInCart.length < 1)? "/shop" : "/cart"}`} className='cart'>
                <AiOutlineShoppingCart size={22}/>
                <div className="number">
                    {productsInCart.length}
                </div>
            </NavLink>

            {isLoggedIn ? <NavLink onClick={()=>{
                props.handleLoader(true, true)
            }} to={"/account"} className='profile'>
                <img src={user.userImg} alt="" />
            </NavLink> : <div className='tologin'>
                <span>Account</span> <FaChevronDown className='down' size={18} />
                <div className="actions">
                    <Link to={"/signup"}>Signup <FaUserPlus /></Link>
                    <Link to={"/login"}>Login <FaArrowRight /></Link>
                </div>
            </div> }
        </div>
        </div>
    </div>
    
  )
}

export default Header