import React, { useContext } from 'react'
import { FaTimes } from 'react-icons/fa'
import UserContext from '../../frontendTestContext/UserContext'

const ConfirmLogout = (props) => {
  const userContextUsed = useContext(UserContext)

  function close(){
    userContextUsed.functions.closeLogout()
  }

  function logout(){
    userContextUsed.functions.closeLogout()
    props.logOut()
  }
  return (
    <div className='confirm logout'>
        <div className="container">
            <div className="info">
                Confirm details
            </div>
            <button className="close">
                <FaTimes />
            </button>
            <div className="content">
                <p>
                    Are you sure you want to <b>Logout?</b>
                </p>
                <small style={{opacity: 0.4}}> <i>click to continue</i> </small>
            </div>
            <div className="actions">
                <div className="controls">
                    <button onClick={()=>{
                      close()
                    }}>Cancel</button>
                    <button style={{backgroundColor: "rgb(230, 20, 20)", color: "#fff"}} onClick={()=>{
                        logout()
                    }}>Logout</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ConfirmLogout