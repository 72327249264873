import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { FaFire, FaArrowRight} from 'react-icons/fa'
import { BiTrendingUp } from 'react-icons/bi'
// import { FiExternalLink } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import UserContext from '../../frontendTestContext/UserContext'
import Footer from '../../components/footer/Footer'
// import LandingPage from '../../components/landing-page/LandingPage'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import ProductHome from '../../components/product/ProductHome'
import HotSwiperlide from '../../components/product/HotSwiperlide'


const Home = (props) => {
    // const [reloadCounter, setReloadCounter] = useState(0);

  

//   useEffect(() => {
//     const reloadInterval = setInterval(() => {
      // Reload the backend by making a request to keep it alive
    //   fetch('https://shoptinga.onrender.com')
       // Replace with your backend URL
    //     .then(response => {
    //       if (response.ok) {
    //         console.log('Backend reloaded successfully.');
    //       } else {
    //         console.error('Failed to reload backend.');
    //       }
    //     })
    //     .catch(error => {
    //       console.error('Error reloading backend:', error);
    //     });

    //   setReloadCounter((prevCounter) => prevCounter + 1);
    // }, 720000); 
    // 12 minutes in milliseconds

    // Cleanup on component unmount
//     return () => clearInterval(reloadInterval);
//   }, []);

//   useEffect(() => {
//     Additional logic that depends on the backend reload ...

//   }, [reloadCounter]);
    const userContextUsed = useContext(UserContext)
    const shopData = userContextUsed.shop
    const navigate = useNavigate()
    const cartData = userContextUsed.cart
    function handleLoader(value, skip){
        props.handleLoader(value, skip)
    }

    const productListX = shopData.products;
    const productList = productListX.map((product) => {
        const hasBeenAddedToCart = cartData.items.some(
        (item) => item.itemId === product.productDetailsFromBackend.productId);

            return {
                ...product,
                productDetailsFromBrowser: {
                ...product.productDetailsFromBrowser,
                hasBeenAddedToCart: hasBeenAddedToCart,
            },
        };
    });
    
    const sortedProducts = productList


    //New and Featured
    const trendingProductsFiltered = sortedProducts.filter((product)=>{
        return product.productDetailsFromBackend.productIsTrending === true
    })

    const reducedArray = trendingProductsFiltered.slice(0, 5);

    const trendingProducts = reducedArray.map((product)=>{

        const cartStatus = product.productDetailsFromBrowser.hasBeenAddedToCart
        const detailsFromBackend = product.productDetailsFromBackend
        // const detailsFromBrowser = product.productDetailsFromBrowser
        return (
            <ProductHome key={detailsFromBackend.productId} handleLoader={props.handleLoader} likeProduct={props.likeProduct} unLikeProduct={props.unLikeProduct} className="product" detailsFromBackend={detailsFromBackend} addToCart={props.addToCart} removeFromCart={props.removeFromCart} cartStatus={cartStatus} />
        )
    })


    //Top Courses
    const topCoursesFiltered = sortedProducts.filter((product)=>{
        return product.productDetailsFromBackend.productCategory === "Courses"
    })

    const topCourses = topCoursesFiltered.slice(0, 15)

    const topCoursesEl = topCourses.map((product)=>{

        const cartStatus = product.productDetailsFromBrowser.hasBeenAddedToCart
        const detailsFromBackend = product.productDetailsFromBackend
        // const detailsFromBrowser = product.productDetailsFromBrowser
        return (
            <ProductHome key={detailsFromBackend.productId} handleLoader={props.handleLoader} likeProduct={props.likeProduct} unLikeProduct={props.unLikeProduct} className="product course" detailsFromBackend={detailsFromBackend} addToCart={props.addToCart} removeFromCart={props.removeFromCart} cartStatus={cartStatus} />
        )
    })

    //Top Software
    const topSoftwaresFiltered = sortedProducts.filter((product)=>{
        return product.productDetailsFromBackend.productCategory === "Softwares"
    })

    const topSoftwares = topSoftwaresFiltered.slice(0, 15)

    const topSoftwaresEl = topSoftwares.map((product)=>{

        const cartStatus = product.productDetailsFromBrowser.hasBeenAddedToCart
        const detailsFromBackend = product.productDetailsFromBackend
        // const detailsFromBrowser = product.productDetailsFromBrowser
        return (
            <ProductHome key={detailsFromBackend.productId} handleLoader={props.handleLoader} likeProduct={props.likeProduct} unLikeProduct={props.unLikeProduct} className="product" detailsFromBackend={detailsFromBackend} addToCart={props.addToCart} removeFromCart={props.removeFromCart} cartStatus={cartStatus} />
        )
    })


    //Under ₦100k
    //Top Courses
    const underFiltered = sortedProducts.filter((product)=>{
        return parseFloat(product.productDetailsFromBackend.productPriceInDollar) < 100000.01
    })

    const under = underFiltered.slice(0, 10)

    const underEl = under.map((product)=>{

        const cartStatus = product.productDetailsFromBrowser.hasBeenAddedToCart
        const detailsFromBackend = product.productDetailsFromBackend
        // const detailsFromBrowser = product.productDetailsFromBrowser
        return (
            <ProductHome key={detailsFromBackend.productId} handleLoader={props.handleLoader} likeProduct={props.likeProduct} unLikeProduct={props.unLikeProduct} className="product" detailsFromBackend={detailsFromBackend} addToCart={props.addToCart} removeFromCart={props.removeFromCart} cartStatus={cartStatus} />
        )
    })

    const [hotProducts, setHotProducts] = useState([
        
    ])

    const slicedX = hotProducts.filter((product)=>{
        return (product.productLikes > 0)
    })

    const slicedXX = slicedX.sort((a, b) => {
        return b.productLikes - a.productLikes;
    });

    const sliced = slicedXX.slice(0, 10)

    const hotProductsEl = sliced.map((hotProduct)=>{
        return (
            <SwiperSlide key={hotProduct.productId} className='hot-product'>
                <HotSwiperlide  likeProduct={props.likeProduct} unLikeProduct={props.unLikeProduct} hotProduct={hotProduct} navigate={navigate} handleLoader={handleLoader} unLikeThis={props.unLikeThis} likeThis={props.likeThis} />
            </SwiperSlide>
        )
    })

    useEffect(()=>{
        const newHot = shopData.products.map((product)=>{
            return (
                {
                    productName: product.productDetailsFromBackend.productName,
                    productPrice: product.productDetailsFromBackend.productPriceInDollar,
                    productSlug: product.productDetailsFromBackend.productUrls.productUrl,
                    productId: product.productDetailsFromBackend.productId,
                    productLikes: product.productDetailsFromBackend.heartCount,
                    productLiked: false,
                    productImages: [
                        product.productDetailsFromBackend.productUrls.productImgUrl,
                        product.productDetailsFromBackend.productMedia.images[0].imageUrl,
                    ]
                }
            )
        })
        setHotProducts(()=>{
            return ([...newHot])
        })
    }, [shopData])

  return (
    <div className='home' id='home'>
       
        <div className="content" id='home-content'>
        
       
            <div className="intro-section">
                <Swiper
                    navigation={true}
                    pagination={{
                      clickable: true,
                    }}
                    autoplay={{
                        delay: 5500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation, Pagination]}
                    className="mySwiper"
                >
                    <div className="hot">
                        Hot <FaFire />
                    </div>
                    {hotProductsEl}
                </Swiper>
                <div className="categoriex">
                    <div className='a' onClick={()=>{
                        navigate("/shop")
                        userContextUsed.functions.handleCategory("courses")
                        handleLoader(true, false)
                    }}>Courses</div>
                    <div className='a' onClick={()=>{
                        navigate("/shop")
                        userContextUsed.functions.handleCategory("softwares")
                        handleLoader(true, false)
                    }}>Softwares</div>
                    <div className='a' onClick={()=>{
                        handleLoader(true, false)
                        userContextUsed.functions.handleCategory("songs")
                        navigate("/shop")
                    }}>Media</div>
                    <div className='a' onClick={()=>{
                        navigate("/shop")
                        handleLoader(true, false)
                        userContextUsed.functions.handleCategory("ebooks")
                    }}>EBooks</div>
                    <button onClick={()=>{
                        navigate("/shop")
                        handleLoader(true, false)
                    }}>
                        <p>Shop</p> <FaArrowRight />
                    </button>
                </div>
            </div>

            {trendingProductsFiltered.length > 0 ? <div className="trending-products">
                <div className='heading'>
                    <div className="title">
                        <p>New & Featured</p>
                        <Link onClick={()=>{
                            handleLoader(true, true)
                        }} to={"/shop"}>
                            <BiTrendingUp />
                        </Link>
                    </div>
                    <div className="sub-title">
                        Enjoy the latest Products from our Top Creators
                    </div>
                </div>
                <div className="container">
                    {trendingProducts}
                </div>
            </div> : <div className='spacer' style={{marginBottom: "70px"}}></div>}
            
            {topCourses.length > 0 ? <div className="trending-products">
                <div className='heading'>
                    <div className="title">
                        <p>Top Courses</p>
                        {/* <Link onClick={()=>{
                            handleLoader(true, true)
                            userContextUsed.functions.handleCategory("courses")
                    }} to={"/shop"}>
                            <BiTrendingUp />
                        </Link> */}
                    </div>
                    {/* <div className="sub-title">
                        Enjoy the latest Products from our Top Creators
                    </div> */}
                </div>
                <div className="container">
                    {topCoursesEl}
                </div>
            </div> : <div className='spacer' style={{marginBottom: "0px"}}></div>}

            {topSoftwares.length > 0 ? <div className="trending-products">
                <div className='heading'>
                    <div className="title">
                        <p>Top Softwares</p>
                    </div>
                </div>
                <div className="container">
                    {topSoftwaresEl}
                </div>
            </div> : <div className='spacer' style={{marginBottom: "0px"}}></div>}
            
            {under.length > 0 ? <div className="trending-products">
                <div className='heading'>
                    <div className="title">
                        <p>Under ₦100k 💕</p>
                    </div>
                </div>
                <div className="container">
                    {underEl}
                </div>
            </div> : <div className='spacer' style={{marginBottom: "0px"}}></div>}
            
            <div className='spacer' style={{marginBottom: "70px"}}></div>
            <Footer />
        </div>
    </div>
  )
}

export default Home
