import React, { useEffect, useState, useContext } from 'react';
import { GoChevronDown } from 'react-icons/go';
import UserContext from '../../frontendTestContext/UserContext';
import Footer from '../../components/footer/Footer';
import ProductHome from '../../components/product/ProductHome';

const Shop = (props) => {
  // const [reloadCounter, setReloadCounter] = useState(0);

  // useEffect(() => {
  //   const reloadInterval = setInterval(() => {
  //     Reload the backend by making a request to keep it alive
  //     fetch('https://gateway.shoptinga.com/api/products')
  //       Replace with your backend URL
  //       .then(response => {
  //         if (response.ok) {
  //           console.log('Backend reloaded successfully.');
  //         } else {
  //           console.error('Failed to reload backend.');
  //         }
  //       })
  //       .catch(error => {
  //         console.error('Error reloading backend:', error);
  //       });

  //     setReloadCounter((prevCounter) => prevCounter + 1);
  //   }, 720000); // 12 minutes in milliseconds

  //   // Cleanup on component unmount
  //   return () => clearInterval(reloadInterval);
  // }, []);

  // useEffect(() => {
    // Additional logic that depends on the backend reload
    // ...

  // }, [reloadCounter]);

  const userContextUsed = useContext(UserContext);
  const shopData = userContextUsed.shop;
  const cartData = userContextUsed.cart;
  const productListX = shopData.products;
  const productList = productListX.map((product) => {
    const hasBeenAddedToCart = cartData.items.some(
      (item) => item.itemId === product.productDetailsFromBackend.productId
    );

    return {
      ...product,
      productDetailsFromBrowser: {
        ...product.productDetailsFromBrowser,
        hasBeenAddedToCart: hasBeenAddedToCart,
      },
    };
  });

  const sortedProducts = productList;

  const [filter, setFilter] = useState('all');

  const filteredShop = sortedProducts.filter((product) => {
    var userFinds = product;
    if (filter === 'all') {
      userFinds = product;
    } else if (filter === 'softwares') {
      userFinds = product.productDetailsFromBackend.productCategory === 'Softwares';
    } else if (filter === 'ebooks') {
      userFinds = product.productDetailsFromBackend.productCategory === 'eBooks';
    } else if (filter === 'songs') {
      userFinds = product.productDetailsFromBackend.productCategory === 'Songs';
    } else if (filter === 'videos') {
      userFinds = product.productDetailsFromBackend.productCategory === 'Videos';
    } else if (filter === 'courses') {
      userFinds = product.productDetailsFromBackend.productCategory === 'Courses';
    }
    return userFinds;
  });

  const categoryx = props.category;

  useEffect(() => {
    if (categoryx !== 'default') {
      setFilter(categoryx);
      userContextUsed.functions.handleCategory('default');
    }
  }, [categoryx, userContextUsed]);

  const shopItems = filteredShop.map((product) => {
    const cartStatus = product.productDetailsFromBrowser.hasBeenAddedToCart;
    const detailsFromBackend = product.productDetailsFromBackend;

    return (
      <ProductHome
        likeProduct={props.likeProduct}
        unLikeProduct={props.unLikeProduct}
        className="product"
        detailsFromBackend={detailsFromBackend}
        addToCart={props.addToCart}
        removeFromCart={props.removeFromCart}
        cartStatus={cartStatus}
      />
    );
  });

  return (
    <div className="shop">
      <div className="content">
        <div className="heading">
          <h2>Shop</h2>
        </div>
        <div className="first-section">
          <div className="title">categories</div>
          <div className="current">
            {filter} <GoChevronDown />
            <div className="categories">
              <button
                onClick={() => {
                  setFilter('all');
                }}
              >
                All
              </button>
              <button
                onClick={() => {
                  setFilter('ebooks');
                }}
              >
                eBooks
              </button>
              <button
                onClick={() => {
                  setFilter('softwares');
                }}
              >
                Softwares
              </button>
              <button
                onClick={() => {
                  setFilter('songs');
                }}
              >
                Songs
              </button>
              <button
                onClick={() => {
                  setFilter('videos');
                }}
              >
                Videos
              </button>
              <button
                onClick={() => {
                  setFilter('courses');
                }}
              >
                Courses
              </button>
            </div>
          </div>
        </div>
        {shopItems.length > 0 ? (
          <div className="second-section container">{shopItems}</div>
        ) : (
          <div className="second-section">
            <div className="failed">
              <h2>
                Check back Later for more{" "}
                <span style={{
                    color: "red"
                }}>{filter}</span> products
              </h2>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Shop;
