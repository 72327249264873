import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { FaHeart } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../frontendTestContext/UserContext'

const HotSwiperlide = (props) => {
    const userContextUsed = useContext(UserContext)
    const [isLiked, setIsLiked]= useState(false)
    const [productLikes, setProductLikes] = useState(0)
    const [hasSet, setHasSet] = useState(false)
    useEffect(()=>{
        if(!hasSet){
            setProductLikes(props.hotProduct.productLikes)
            setHasSet(true)
        }
    },[props.hotProduct.productLikes, hasSet])

    const navigate = useNavigate()

    async function likeProduct(productId){
        const access = localStorage.getItem("access")
        const url = `https://shoptinga.onrender.com/api/products/heart/${productId}`
        if(access && userContextUsed.isLoggedIn){
        try{
            const response = await axios.post(url,{}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${access}`,
                'Accept': 'application/json',
            }
            })
            console.log(response.data)
        } catch(error){
            console.error(error)
            userContextUsed.functions.handleNotification("Failed to Like product on server", "warn")
        }
        } else{
        setTimeout(()=>{
            navigate("/login")
            userContextUsed.functions.handleNotification("SignIn to your Account to Like products 👍", "warn")
        }, 10)
        }
    }

    async function unLikeProduct(productId){
        const access = localStorage.getItem("access")
        const url = `https://shoptinga.onrender.com/api/products/heart/${productId}`
        if(access && userContextUsed.isLoggedIn){
        try{
            const response = await axios.delete(url,{}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${access}`,
                'Accept': 'application/json',
            }
            })
            console.log(response.data)
        } catch(error){
            console.error(error)
            userContextUsed.functions.handleNotification("Failed to delete Like on server", "warn")
        }
        } else{
        setTimeout(()=>{
            navigate("/login")
            userContextUsed.functions.handleNotification("SignIn to your Account to Like products 👍", "warn")
        }, 10)
        }
    }
  return (
    <>
        <div className="details">
        <h2> <p>{props.hotProduct.productName}</p> <div className="likes">
        <p>{productLikes}</p> <button className={isLiked ? "isLiked" : ""} onClick={()=>{
            if(isLiked){
                setIsLiked(false)
                setProductLikes((prev)=>{
                    return parseInt(prev)-1
                })
                unLikeProduct(props.hotProduct.productId)
                
            } else{
                likeProduct(props.hotProduct.productId)
                setIsLiked(true)
                setProductLikes((prev)=>{
                    return parseInt(prev)+1
                })
            }
        }}> {isLiked ? <FaHeart color='red' style={{color:"red"}} /> : <FaHeart />} </button> 
        </div> </h2>
            <div className="action">
                <p>₦{props.hotProduct.productPrice}</p>
                <button style={{pointerEvents: "all"}} onClick={()=>{
                    props.handleLoader(true, false)
                    props.navigate(`/product/${props.hotProduct.productSlug}`)
                    props.handleLoader(true)
                }} className="buy-now">
                    Buy Now
                </button>
            </div>
        </div>
        <div className="product-img i" style={{backgroundImage: ` url(${props.hotProduct.productImages[0]})`}}>
        </div>
        <div className="product-img ii" style={{backgroundImage: ` url(${props.hotProduct.productImages[1]})`}}>
        </div>
    </>
  )
}

export default HotSwiperlide
