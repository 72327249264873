import React from 'react'
import {Link} from "react-router-dom"
import {BsFillHeartbreakFill} from "react-icons/bs"

const Oops = () => {
  return (
    <div className='oops'>
      <div className="content">
        <div className="not-found">
            <BsFillHeartbreakFill />
            <span>404</span>
            <h2>Page was not Found</h2>
            <p>The page you are looking for does not exist. It might have been moved or deleted</p>
            <Link to={"/"}>
              <p>BACK TO HOME</p>
            </Link>
        </div>
      </div>
    </div>
  )
}

export default Oops