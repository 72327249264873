import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import {Link, useLocation} from "react-router-dom"
import UserContext from '../../frontendTestContext/UserContext'
import { FaTimes } from 'react-icons/fa'

const LoginToView = () => {
  const location = useLocation()
  const [showMe, setShowMe] = useState(false)
  const userContextUsed = useContext(UserContext)
  const isLoggedIn = userContextUsed.isLoggedIn
  useEffect(()=>{
    const path = location.pathname
    if((path.includes("account")||path.includes("become-a-seller")) && isLoggedIn === false){
        setShowMe(true)
    } else{
        setShowMe(false)
    }
  }, [location.pathname, isLoggedIn])
  return (
    <div className={showMe ? 'become-seller-to-view show forlogin': 'become-seller-to-view forlogin'}>
        <div className="gotohome" onClick={()=>{
          setShowMe(false)
        }}>
          <FaTimes />
        </div>
        <div className="holder">
            <Link to={"/login"}>
                Login to View this Page
            </Link>
        </div>
    </div>
  )
}

export default LoginToView