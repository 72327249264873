import React, { useEffect, useRef } from 'react'
import { GoChevronDown } from 'react-icons/go'
// import { FaPlus, FaMinus } from 'react-icons/fa'
// import { FiExternalLink } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import { BsSearch } from 'react-icons/bs'
import UserContext from '../../frontendTestContext/UserContext'
import { useContext } from 'react'
import { useState } from 'react'
import Footer from '../../components/footer/Footer'
import ProductHome from '../../components/product/ProductHome'

const Search = (props) => {
  const searchRef = useRef(null)
  const navigate = useNavigate()
  const userContextUsed = useContext(UserContext)
    const shopData = userContextUsed.shop
    const [filter, setFilter] = useState("all")
    const sortedProducts = shopData.products.sort((a, b) => a.productDetailsFromBackend.productName.localeCompare(b.productDetailsFromBackend.productName));
    const searchedShop = sortedProducts.filter((product)=>{
        const searchTerm = props.search.toLowerCase()
        return product.productDetailsFromBackend.productName.toLowerCase().includes(searchTerm.toLowerCase())
    })
    const filteredShop = searchedShop.filter((product)=>{
      var userFinds = product
      if(filter === "all"){
          userFinds = product
      }
      else if(filter === "softwares"){
          userFinds = product.productDetailsFromBackend.productCategory === "Softwares"
      }
      else if(filter === "ebooks"){
          userFinds = product.productDetailsFromBackend.productCategory === "eBooks"
      }
      else if(filter === "songs"){
          userFinds = product.productDetailsFromBackend.productCategory === "Songs"
      }
      else if(filter === "videos"){
          userFinds = product.productDetailsFromBackend.productCategory === "Videos"
      }
      else if(filter === "courses"){
          userFinds = product.productDetailsFromBackend.productCategory === "Courses"
      }
      return userFinds
  })

    const categoryx = props.category

    useEffect(()=>{
      if(categoryx !== "default"){
        setFilter(categoryx)
        userContextUsed.functions.handleCategory("default")
      }
    }, [categoryx, userContextUsed])

    const shopItems = filteredShop.map((product)=>{
        const cartStatus = product.productDetailsFromBrowser.hasBeenAddedToCart
        const detailsFromBackend = product.productDetailsFromBackend
        // const detailsFromBrowser = product.productDetailsFromBrowser
        return (
          <ProductHome likeProduct={props.likeProduct} unLikeProduct={props.unLikeProduct} className="product" detailsFromBackend={detailsFromBackend} addToCart={props.addToCart} removeFromCart={props.removeFromCart} cartStatus={cartStatus} />
        )
    })
  return (
    <div className='shop'>
      <div className="content">
        <div className="heading">
          <h2>
            Search{props.search.length > 0 && <span>: "{props.search}"</span>}
          </h2>
          <form className="search-bar" onSubmit={(e)=>{
            e.preventDefault()
            props.handleLoader(true)
          }}>
            <button>
              <BsSearch className="search-icon" />
            </button>
            <input ref={searchRef} type="search" value={props.search} onChange={(e)=>{
                props.setSearch(e.target.value)
                if(props.search.length > 2){
                    navigate("/search")
                }
            }} placeholder="Search Here" />
          </form>
        </div>
        <div className="first-section">
          <div className="title">
            categories
          </div>
          <div className="current">
            {filter} <GoChevronDown />
            <div className="categories">
              <button onClick={()=>{
                setFilter("all")
              }}>
                All
              </button>
              <button onClick={()=>{
                setFilter("ebooks")
              }}>
                eBooks
              </button>
              <button onClick={()=>{
                setFilter("softwares")
              }}>
                Softwares
              </button>
              <button onClick={()=>{
                setFilter("songs")
              }}>
                Songs
              </button>
              <button onClick={()=>{
                setFilter("videos")
              }}>
                Videos
              </button>
              <button onClick={()=>{
                setFilter("courses")
              }}>
                Courses
              </button>
            </div>
          </div>
        </div>
        {shopItems.length > 0 ? <div className="second-section container">
        {shopItems}
        </div> : <div className="second-section">
            <div className="failed">
                <h2>Didn't Find that Product</h2>
                <p>
                Try searching for another term or go back to <Link to={"/shop"}>Shop</Link>
                </p>
            </div>
        </div>}
        <Footer />
      </div>
    </div>
  )
}

export default Search