import React, { useContext } from 'react'
import {FaRegUserCircle, FaUserCircle} from "react-icons/fa"
import {IoHome, IoHomeOutline, IoSettings} from "react-icons/io5"
import {IoSettingsOutline} from "react-icons/io5"
import {HiOutlineShoppingBag, HiShoppingBag} from "react-icons/hi2"
// import {BsSearch} from "react-icons/bs"
import {RxDashboard} from "react-icons/rx"
import {FiLogOut} from "react-icons/fi"
import { NavLink, useLocation } from 'react-router-dom'
import { MdDashboardCustomize } from 'react-icons/md'
import logoImg from "../../assets/logo.png"
import UserContext from '../../frontendTestContext/UserContext'

const Sidebar = (props) => {
    const location = useLocation()
    const pathname = location.pathname
    const userContextUsed = useContext(UserContext)
    const isLoggedIn = userContextUsed.isLoggedIn
  return (
    <div className='sidebar'>
        <div className="logo">
            <NavLink onClick={()=>{
                props.handleLoader(true, false)
            }}  to={"/"}>
                <img src={logoImg} alt="shoptinga-logo" />
                {/* <FaShoppingCart  color='#fff' /> */}
            </NavLink>
        </div>

        <div className="links-holder">
            <NavLink onClick={()=>{
                props.handleLoader(true, false)
            }} to={"/"} className={`${pathname.includes("faqs") && "active"}`}>
                <IoHomeOutline />
                <IoHome />
            </NavLink>
            <NavLink onClick={()=>{
                props.handleLoader(true, false)
            }}  className={`${pathname.includes("cart") && "active"} ${pathname.includes("product") && "active"} ${pathname.includes("seller") && "active"} ${pathname.includes("search") && "active"}`} to={"/shop"} >
                <HiOutlineShoppingBag />
                <HiShoppingBag />
            </NavLink>
            {userContextUsed.isLoggedIn && <NavLink to={"/dashboard"}>
                <RxDashboard />
                <MdDashboardCustomize />
            </NavLink>}
            {userContextUsed.isLoggedIn && <NavLink onClick={()=>{
                if(isLoggedIn){
                    props.handleLoader(true, "customi")
                } else{
                    
                }
            }}  to={"/account"}>
                <FaRegUserCircle />
                <FaUserCircle />
            </NavLink>}
            {userContextUsed.isLoggedIn==="axaxa" && <NavLink to={"/settings"} style={{display:"flex"}} className='settings__link'>
                <IoSettingsOutline />
                <IoSettings />
            </NavLink>}
        </div>
        {userContextUsed.isLoggedIn && <div onClick={()=>{
            userContextUsed.functions.confirmLogout()
        }} className="logout__link">
            <FiLogOut fontWeight={700} size={23} color='#444'/>
        </div>}
    </div>
  )
}

export default Sidebar