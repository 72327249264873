import React, { useContext } from 'react'
import {MdOutlineAddShoppingCart, MdShoppingCartCheckout} from "react-icons/md"
import {GiWallet} from "react-icons/gi"
// import {LuExternalLink} from "react-icons/lu"
import { Link, useNavigate } from 'react-router-dom'
// import defaultImg from "../../assets/signup.jpg"
import { HiExternalLink } from 'react-icons/hi'
import { FaEdit, FaExternalLinkAlt, FaPlus } from 'react-icons/fa'
import { useState } from 'react'
// import Footer from '../../components/footer/Footer'
import UserContext from '../../frontendTestContext/UserContext'

const Dashboard = (props) => {
  const [fullNew, setFullNew] = useState(true)
  const userContextUsed = useContext(UserContext)
  // const productsList = userContextUsed.shop.products
  const sellerInfo = userContextUsed.user.sellerInfo.info
  const myProducts = userContextUsed.user.sellerInfo.productsCreated.filter((item)=>{
    return item.productOwner === userContextUsed.user.userId
  })
  const activityList = myProducts
  const navigate = useNavigate()

  
  const activityEl = activityList.map((activity)=>{
    return (
      <div className="activity" key={activity.productId} style={{overflow: "hidden"}}>
        <img src={activity.productThumbnail} style={{objectFit: "cover", objectPosition: "center"}} alt="owner" />
        <div className="details">
          <h3>
            {activity.productName}
          </h3>
          <p>
            Price ~  ₦{activity.productPrice ? activity.productPrice : "$....."}
          </p>
        </div>
        <div className="time" style={{
          opacity: "0",
          pointerEvents: "none"
        }}>
          12 mins ago
        </div>
        <div className="actions" >
          <div className="preview-product"  onClick={()=>{
            console.clear()
            props.handleLoader(true)
            navigate(`/product/${activity.productUrl}`)
          }}>
            View <FaExternalLinkAlt />
          </div>
        </div>
      </div>
    )
  })
  return (
    <div className='dashboard' onTouchStart={()=>{
      setFullNew(false)
    }}>
      <div className="content">
        <div className="heading">
          <h2>
            Dashboard
          </h2>
          <Link to={"/account/edit"}>
            Edit Profile <FaEdit />
          </Link>
        </div>

        <div className="first-section">
          <div className="left">
            <div className="earnings">
              <div className="svg">
                <GiWallet />
              </div>
              <div className="details">
                <p>
                   ₦{userContextUsed.user.sellerInfo.info.totalEarnings ? userContextUsed.user.sellerInfo.info.totalEarnings : "..."}
                </p>
                <p>
                  Total earnings
                </p>
              </div>
            </div>
            <div className="products">
              <div className="svg">
                <MdOutlineAddShoppingCart />
              </div>
              <div className="details">
                <p>
                  {activityList ? activityList.length : "...."}
                </p>
                <p>
                  Items created
                </p>
              </div>
            </div>
            <div className="purchased">
              <div className="svg">
                <MdShoppingCartCheckout />
              </div>
              <div className="details">
                <p>
                  {sellerInfo.totalSales===false ? sellerInfo.totalSales : "..."}
                </p>
                <p>
                  Items sold
                </p>
              </div>
              <Link style={{opacity: "0.3", pointerEvents: "none"}} to={"/dashboard/sales"}>
                <HiExternalLink />
              </Link>
            </div>
          </div>

          <Link to={"/dashboard/new-product"} className={fullNew ? "right" : "right hideP"}  onTouchStart={(e)=>{
            e.stopPropagation()
            setFullNew(true)
          }}>
            <FaPlus />
            <p>
              New Item
            </p>
          </Link>
        </div>

        {activityList.length > 0 && <div className="second-section">
          <div className="section-i">
            <div className="top">
              <h2>
                My Products
              </h2>
              {/* <Link to={"/history"}> */}
              <Link style={{display: "none"}} to={"/history"}>
                See more
              </Link>
            </div>
            <div className="bottom">
              {activityEl}
            </div>
          </div>
        </div>}
        {/* <Footer /> */}
      </div>
    </div>
  )
}

export default Dashboard
