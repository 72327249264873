import React from 'react'
import Footer from '../../components/footer/Footer'

const TermsOfService = () => {
  return (
    <div className='home'>
        <div className="content">
            <h2>
                Terms of Service
            </h2>
            <div className="trending-productss">
                <div className="container" style={{marginTop: "-10px"}}>

                    Introduction: <br />
                    Welcome to Shoptinga Online! As you have just
                    clicked our Terms of Service, please carefully read the following pages. This, as
                    well as the privacy policy and the affiliate network policy are legal documents so
                    some of the language is necessarily “legalese” but we have tried to make them
                    as readable as possible.
                    These Terms of Service govern your use of our web
                    pages located at www.shoptinga.online and our Marketplace Shoptinga operated by Shoptinga Online Services LLC.
                    Our Privacy Policy also governs your use of our Service and explains how we
                    collect, safeguard and disclose information that results from your use of our web
                    pages. Please read it here.
                    Your agreement with us includes these Terms and our Privacy Policy
                    (“Agreements”). You acknowledge that you have read and understood
                    Agreements, and agree to be bound by them.
                    If you do not agree with (or cannot comply with) these terms, then you may not
                    use the service, however, please feel free to reach out to us via email at
                    support@shoptinga.online if you have any reservations or concerns. These
                    Terms apply to all visitors, users and others who wish to access or use our
                    Service.<br /><br />
                    Communications:<br />
                    By creating an Account on our Service, you agree to subscribe to newsletters,
                    marketing or promotional materials and other information we may send.
                    However, you may opt out of receiving any, or all, of these communications from
                    us by following the unsubscribe link or by reaching out via email.
                    3. Purchases:
                    If you wish to purchase any product or service made available through Service
                    (“Purchase”), you may be asked to supply certain information relevant to your
                    Purchase including, without limitation, your debit/credit card number, the
                    expiration date of your debit/credit card, your billing address, and your shipping
                    information.
                    You represent and warrant that: (i) you have the legal right to use any debit/credit
                    card(s) or other payment method(s) in connection with any Purchase; and that (ii)
                    the information you supply to us is true, correct and complete.
                    We may employ the use of third party services for the purpose of facilitating
                    payment and the completion of Purchases. By submitting your information, you
                    grant us the right to provide the information to these third parties subject to our
                    Privacy Policy.
                    We reserve the right to refuse or cancel your sale at any time for reasons
                    including but not limited to: product or service availability, errors in the
                    description or price of the product or service, error in your order or other reasons.
                    We reserve the right to refuse or cancel your order upon suspicion of a
                    fraudulent, an unauthorized or otherwise illegal transaction.
                    We are currently unable to reverse processing fees and purchase of products are
                    final.<br /><br />
                    Contests, Sweepstakes and Promotions:<br />
                    All contests, sweepstakes or other promotions (collectively, “Promotions”) made
                    available through Service may be governed by rules that are separate from these
                    Terms of Service. If you participate in any Promotions, please review the
                    applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict
                    with these Terms of Service, Promotion rules will apply.
                    5. Referrals & Affiliate program:
                    Shoptinga has a referral and affiliate program that enables existing customers/
                    users to refer new users and subscribers. Details of our referrals and how to join
                    our affiliate Network is contained in our Affiliate Network Policy.
                    Code of Conduct:
                    a. Users are expected to abide by the following Code of Conduct guiding our
                    community:<br />
                    ● Illegal Activity: We prohibit any form of use of Shoptinga platform
                    for any illegal activity. You are not allowed to use our Platform to
                    engage in any kind of conduct that violates any applicable federal,
                    state, local, or international law or regulation (including, without
                    limitation, any laws regarding the export of data or software to and
                    from the US, Nigeria, or your country).
                    If any unlawful activity occurs, it will be subject to investigation, and
                    the legal consequences to which the involved parties may be bound
                    can vary<br />
                    ● Fraud: We have zero tolerance for any form of fraudulent activity or
                    transaction including but not limited to Money Laundering.<br />
                    ● Bad Code: You shall not use the Shoptinga Platform to transmit,
                    distribute, send, etc. viruses, worms, or any other code that may be
                    deemed dangerous, harmful, or destructive in any manner<br />
                    ● Spamming: You shall not use the Shoptinga Platform to engage in
                    any activities that will result in sending spam to anyone on
                    Shoptinga platform, including Shoptinga (and its employees),
                    Subscribers, and Purchasers.<br />
                    ● Respect: We encourage users to be civil and respectful at all times.<br />
                    ● Exploitation: You shall not use Shoptinga to try to gather personal
                    information on anyone, outside of the limited permissible uses for
                    offering your Content<br />
                    ● Impersonation: You shall not impersonate anybody including
                    Shoptinga Online Services LLC, any of its employees, users of
                    Shoptinga or members of the general public.
                    Upon discovery of impersonation,we reserve the right to deactivate
                    the infringing account immediately<br />
                    ● Data Mining or Bots: You shall not use any data mining, robots, or
                    similar data gathering or extraction methods; <br />
                    ● Use Other Than Intended: You shall not use Shoptinga or any
                    content contained on the Platform for any purposes other than
                    intended
                    b. Upon violation of our Code of Conduct, we reserve the right to delete a
                    user's account and Content from Shoptinga Online. Whether conduct
                    violates our Code of Conduct will be determined in Shoptinga’s sole
                    discretion.<br /><br />
                    Subscriptions:<br />
                    Some parts of Service are billed on a subscription basis (“Subscription(s)”). You
                    shall be billed in advance on a recurring and periodic basis (“Billing Cycle”).
                    Billing cycles are set either on a monthly or annual basis, depending on the type
                    of subscription plan you select when purchasing a Subscription
                    At the end of each Billing Cycle, your Subscription will automatically renew under
                    the exact same conditions unless you cancel it or Shoptinga Online Services LLC
                    cancels it. You may cancel your Subscription renewal either through your online
                    account management page or by contacting Shoptinga Online customer support
                    team.
                    A valid payment method, including debit card, credit card or Crypto wallet
                    address, is required to process the payment for your subscription. You shall
                    provide Shoptinga Online with accurate and complete billing information
                    including full name, address, state, zip code, telephone number, and a valid
                    payment method information. By submitting such payment information, you
                    automatically authorize Shoptinga Online Services LLC to charge all Subscription
                    fees incurred through your account to any such payment instruments.
                    Upon failure of automatic billing, Shoptinga Online will issue an electronic invoice
                    indicating that you must proceed manually, within a certain deadline date, with
                    the full payment corresponding to the billing period as indicated on the invoice.
                    In the event that you discontinue your paid subscription, you will no longer have
                    access to all the paid features on the Service, consequently, our customers will
                    lose access to features that are exclusive to paying members
                    only/subscription-based products.
                    7. KYC:
                    We may need you to verify your identity to access our services. We would request
                    your banking details, a Valid ID and/or proof of address. You consent to us
                    sharing these details with secure verifying agencies and databases. You also
                    permit us to submit these details to regulatory government agencies where
                    required.
                    8. Fee Changes:
                    Shoptinga, in its sole discretion and at any time, may modify Subscription fees
                    for the Subscriptions. Any Subscription fee change shall become effective at the
                    end of the subsisting Billing Cycle. Shoptinga shall provide you with a reasonable
                    prior notice of any change in Subscription fees to give you an opportunity to
                    terminate your Subscription before such change becomes effective. Your
                    continued use of Service after Subscription fee change comes into effect shall
                    constitute an implied agreement to pay the modified Subscription fee amount.
                    9. Chargebacks:
                    In the occurrence of a chargeback, you shall be liable to an additional $15 (or the
                    local currency equivalent) charge.
                    10. Refund Policy:
                    We issue refunds for monthly subscriptions if the subscription is said to have run
                    successfully for less than ten (10) days.
                    For annual subscriptions, we issue refunds for the months the subscriber has not
                    used the service for.
                    A month in this case shall be said to have been completed if the subscriber has
                    been active on the service for at least nine (9) days
                    Once a request for cancellation of subscription/termination of use of service is
                    received, refunds shall be processed within thirty (30) days from when the
                    request is made
                    11. Content:
                    Our Service allows users to post, link, store, share and otherwise make available
                    certain information, text, graphics, videos, or other material (“Content”). Users are
                    responsible for Content that they post on or through Service, including its legality,
                    reliability, and appropriateness
                    By posting Content on or through Service, User represents and warrants that: (i)
                    Content is their Intellectual Property and/or they have the right or licence to use it
                    and the right to grant us the rights and license as provided in these Terms, and
                    (ii) that the posting of your Content on or through Service does not infringe on the
                    privacy rights, publicity rights, copyrights, contract rights or any other rights of
                    any person or entity- tangible or intangible. We reserve the right to delete the
                    account of anyone found to be infringing on any of the above rights.
                    You reserve any and all of your rights to any Content you submit, post or display
                    on or through Service and you are responsible for protecting those rights. We
                    take no responsibility and assume no liability for Content you or any third party
                    posts on or through Service. However, by posting Content using Service you grant
                    us the right and license to use, modify, publicly perform, publicly display,
                    reproduce, and distribute such Content on and through Service. You agree that
                    this license includes the right for us to make your Content available to other
                    users of Service, who may also use your Content subject to these Terms.
                    Shoptinga Online Services LLC has the right but not the obligation to monitor and
                    edit all Content provided by users.
                    12.Prohibited Uses:
                    You may use Service only for lawful purposes and in accordance with Terms. You
                    agree not to use Service:
                    a. In any way that violates any applicable national or international law or
                    regulation.
                    b. For the purpose of exploiting, harming, or attempting to exploit or harm
                    minors in any way by exposing them to inappropriate content or otherwise.
                    c. To transmit, or procure the sending of, any advertising or promotional
                    material, including any “junk mail”, “chain letter,” “spam,” or any other
                    similar solicitation
                    d. To impersonate or attempt to impersonate Company, a Company
                    employee, another user, or any other person or entity.
                    e. In any way that infringes upon the rights of others, or in any way that is
                    illegal, threatening, fraudulent, or harmful, or in connection with any
                    unlawful, illegal, fraudulent, or harmful purpose or activity.
                    f. To engage in any other conduct that restricts or inhibits anyone’s use or
                    enjoyment of Service, or which, as determined by us, may harm or offend
                    Company or users of Service or expose them to liability.
                    g. To transmit a message that is deemed “grossly offensive, pornographic or
                    of an indecent, obscene or menacing character”
                    Additionally, you shall not:
                    h. Use Service in any manner that could disable, overburden, damage, or
                    impair Service or interfere with any other party’s use of Service, including
                    their ability to engage in real time activities through Service.
                    i. Use any robot, spider, or other automatic device, process, or means to
                    access Service for any purpose, including monitoring or copying any of the
                    material on Service.
                    j. Use any manual process to monitor or copy any of the material on Service
                    or for any other unauthorized purpose without our prior written consent.
                    k. Use any device, software, or routine that interferes with the proper working
                    of Service.
                    l. Introduce any viruses, trojan horses, worms, logic bombs, or other material
                    which is malicious or technologically harmful.
                    m. Attempt to gain unauthorized access to, interfere with, damage, or disrupt
                    any parts of Service, the server on which Service is stored, or any server,
                    computer, or database connected to Service
                    n. Attack Service via a denial-of-service attack or a distributed
                    denial-of-service attack
                    o. Take any action that may damage or falsify Company rating.
                    p. Otherwise attempt to interfere with the proper working of Service
                    13.Analytics
                    We may use third-party Service Providers to monitor and analyze the use of our
                    Service.
                    Google Analytics
                    Google Analytics is a web analytics service offered by Google that tracks and
                    reports website traffic. Google uses the data collected to track and monitor the
                    use of our Service. This data is shared with other Google services. Google may
                    use the collected data to contextualize and personalize the ads of its own
                    advertising network.
                    For more information on the privacy practices of Google, please visit the Google
                    Privacy Terms web page: https://policies.google.com/privacy?hl=en
                    We also encourage you to review the Google's policy for safeguarding your data:
                    https://support.google.com/analytics/answer/6004245
                    Cloudflare Analytics
                    Cloudflare analytics is a web analytics service operated by Cloudflare Inc. Read
                    the Privacy Policy here: https://www.cloudflare.com/privacypolicy/
                    Mixpanel
                    Mixpanel is provided by Mixpanel Inc.
                    For more information on what type of information Mixpanel collects, please visit
                    the Terms of Use page of Mixpanel: https://mixpanel.com/terms/
                    14. Use By Minors:
                    Service is intended only for access and use by individuals at least eighteen (18)
                    years old. By accessing or using any of Company’s Service, you warrant and
                    represent that you are at least eighteen (18) years of age and with the full
                    authority, right, and capacity to enter into this agreement and abide by all of the
                    terms and conditions of Terms. If you are not at least eighteen (18) years old, you
                    are prohibited from both access and usage of Service.
                    15. Accounts:
                    When you create an account with us, you guarantee that you are above the age of
                    13, and that the information you provide us is accurate, complete, and current at
                    all times. Inaccurate, incomplete, or obsolete information may result in the
                    immediate termination of your account on Service.
                    Users are responsible for maintaining the confidentiality of account and
                    password, including but not limited to the restriction of access to your computer
                    and/or account. You agree to accept responsibility for any and all activities or
                    actions that occur under your account and/or password, whether your password
                    is with our Service or a third-party service. You must notify us immediately upon
                    becoming aware of any breach of security or unauthorized use of your account.
                    You may not use as a username the name of another person or entity or that is
                    not lawfully available for use, a name or trademark that is subject to any rights of
                    another person or entity other than you, without appropriate authorization. You
                    may not use as a username any name that is offensive, vulgar or obscene.
                    We reserve the right to refuse service, terminate accounts, remove or edit
                    content, or cancel orders at our sole discretion without any form of liability.
                    16. Intellectual Property:
                    Service and its original content (excluding Content provided by users), features
                    and functionality are and will remain the exclusive property of Shoptinga Online
                    Services LLC and its licensors. Service is protected by copyright, trademark, and
                    other laws of the United States and foreign countries. Our trademarks and trade
                    dress may not be used in connection with any product or service without the prior
                    written consent of Shoptinga Online Services LLC.
                    17. Copyright Policy:
                    We respect the intellectual property rights of others. It is our policy to respond to
                    any claim that Content posted on Service infringes on the copyright or other
                    intellectual property rights (“Infringement”) of any person or entity
                    If you are a copyright owner, or authorized on behalf of one, and you believe that
                    the copyrighted work has been copied in a way that constitutes copyright
                    infringement, please submit your claim via email to copyright@shoptinga.online,
                    with the subject line: “Copyright Infringement” and include in your claim a
                    detailed description of the alleged Infringement as detailed below, under “DMCA
                    Notice and Procedure for Copyright Infringement Claims”
                    You may be held accountable for damages (including costs and attorneys' fees)
                    for misrepresentation or bad-faith claims on the infringement of any Content
                    found on and/or through Service on your copyright.
                    18.DMCA Notice and Procedure for Copyright Infringement Claims:
                    You may submit a notification pursuant to the Digital Millennium Copyright Act
                    (DMCA) by providing our Copyright Agent with the following information in
                    writing (see 17 U.S.C 512(c)(3) for further detail):
                    a. an electronic or physical signature of the person authorized to act on
                    behalf of the owner of the copyright's interest;
                    b. a description of the copyrighted work that you claim has been infringed,
                    including the URL (i.e., web page address) of the location where the
                    copyrighted work exists or a copy of the copyrighted work;
                    c. identification of the URL or other specific location on Service where the
                    material that you claim is infringing is located;
                    d. your address, telephone number, and email address;
                    e. a statement by you that you have a good faith belief that the disputed use
                    is not authorized by the copyright owner, its agent, or the law;
                    f. a statement by you, made under penalty of perjury, that the above
                    information in your notice is accurate and that you are the copyright owner
                    or authorized to act on the copyright owner's behalf.
                    You can contact our Copyright Agent via email at copyright@shoptinga.online
                    19. Error Reporting and Feedback:
                    You may provide us directly at support@shoptinga.online with information and
                    feedback concerning errors, suggestions for improvements, ideas, problems,
                    complaints, and other matters related to our Service (“Feedback”). You
                    acknowledge and agree that: (i) you shall not retain, acquire or assert any
                    intellectual property right or other right, title or interest in or to the Feedback; (ii)
                    Company may have developed ideas similar to the Feedback; (iii) Feedback does
                    not contain confidential information or proprietary information from you or any
                    third party; and (iv) Company is not under any obligation of confidentiality with
                    respect to the Feedback. In the event the transfer of the ownership to the
                    Feedback is not possible due to applicable mandatory laws, you grant Company
                    and its affiliates an exclusive, transferable, irrevocable, free-of-charge,
                    sub-licensable, unlimited and perpetual right to use (including copy, modify,
                    create derivative works, publish, distribute and commercialize) Feedback in any
                    manner and for any purpose
                    20.Links To Other Websites:
                    Our Service may contain links to third-party websites or services that are not
                    owned or controlled by Shoptinga Online Services LLC.
                    Shoptinga has no control over and assumes no responsibility for the content,
                    privacy policies, or practices of any third-party websites or services. We do not
                    warrant the offerings of any of these entities/individuals or their websites.
                    YOU ACKNOWLEDGE AND AGREE THAT SHOPTINGA ONLINE SERVICES LLC
                    SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY
                    DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION
                    WITH THE USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES
                    AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR
                    SERVICES.
                    WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
                    POLICIES OF ANY THIRD-PARTY WEBSITES OR SERVICES THAT YOU VISIT.
                    21. Disclaimer Of Warranty:
                    THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
                    AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES
                    OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR
                    SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED
                    THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR
                    CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE
                    RISK.
                    NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY
                    MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                    COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                    AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING,
                    NEITHER COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY
                    REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY
                    SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
                    RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                    CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE
                    ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE
                    SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES
                    WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS
                    AS A PLATFORM THAT PROVIDES FOR BUYING AND SELLING OF DIGITAL
                    GOODS AND SERVICES, THE COMPANY WILL NOT BE LIABLE FOR ANY
                    FRAUDULENT, FAILED, OR INCOMPLETE CARD TRANSACTION THAT TAKES
                    PLACE ON OUR PLATFORM BY USERS, HOWEVER, WE RESERVE THE RIGHT TO
                    DIVULGE THE DETAILS OF SUCH TRANSACTION TO APPROPRIATE
                    AUTHORITIES IN ORDER TO HELP WITH THE INVESTIGATION PROCESS
                    COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                    EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT
                    LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
                    AND FITNESS FOR PARTICULAR PURPOSE
                    THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                    22.Governing Law:
                    These Terms shall be governed and construed in accordance with the laws of the
                    Federal Republic of Nigeria without regard to its conflict of law provisions.
                    Our failure to enforce any right or provision of these Terms will not be considered
                    a waiver of those rights. If any provision of these Terms is held to be invalid or
                    unenforceable by a court, the remaining provisions of these Terms will remain in
                    effect. These Terms constitute the entire agreement between us regarding our
                    Service and supersede and replace any prior agreements we might have had
                    between us regarding Service.
                    23.Changes To Service:
                    We reserve the right to withdraw or amend our Service, and any service or
                    material we provide via Service, in our sole discretion without notice. We will not
                    be liable if for any reason all or any part of the Service is unavailable at any time
                    or for any period. From time to time, we may restrict access to some parts of the
                    Service, or the entire Service, to users, including registered users.
                    24.Amendments To Terms:
                    We may amend Terms at any time by posting the amended terms on this site. It
                    is your responsibility to review these Terms periodically.
                    Your continued use of the Platform following the posting of revised Terms means
                    that you accept and agree to the changes. You are expected to check this page
                    frequently so you are aware of any changes, as they are binding on you.
                    By continuing to access or use our Service after any revisions become effective,
                    you agree to be bound by the revised terms. If you do not agree to the new terms,
                    you are no longer authorized to use the Service
                    25.Waiver And Severability:
                    No waiver by the Company of any term or condition set forth in Terms shall be
                    deemed a further or continuing waiver of such term or condition or a waiver of
                    any other term or condition, and any failure of the Company to assert a right or
                    provision under Terms shall not constitute a waiver of such right or provision
                    If any provision of Terms is held by a court or other tribunal of competent
                    jurisdiction to be invalid, illegal or unenforceable for any reason, such provision
                    shall be eliminated or limited to the minimum extent such that the remaining
                    provisions of Terms will continue in full force and effect.
                    26.Acknowledgement:
                    BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
                    THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND
                    BY THEM
                    27.Contact Us
                    Please send your feedback, comments, and requests for technical support:
                    By email: support@shoptinga.online.
                    Privacy Policy
                    1. Introduction:
                    Welcome to Shoptinga Online.
                    Shoptinga . (“us”, “we”, or “our”) operates www.shoptinga.online and Shoptinga
                    Marketplace (hereinafter referred to as “Service ”).
                    Our Privacy Policy governs your visit to www.shoptinga.online and Shoptinga
                    Marketplace, and explains how we collect, safeguard and disclose information
                    that results from your use of our Service.
                    We use your data to provide and improve Service. By using Service, you agree to
                    the collection and use of information in accordance with this policy. Unless
                    otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have
                    the same meanings as in our Terms and Conditions.
                    Our Terms and Conditions (“ Terms ”) govern all use of our Service and together
                    with the Privacy Policy constitutes your agreement with us (“ agreement ”).
                    2. Definition:
                    SERVICE means the www.shoptinga.online website and Shoptinga Marketplace
                    operated by Shoptinga.
                    PERSONAL DATA means data about a living individual who can be identified from
                    those data (or from those and other information either in our possession or likely
                    to come into our possession).
                    USAGE DATA is data collected automatically either generated by the use of
                    Service or from Service infrastructure itself (for example, the duration of a page
                    visit).
                    COOKIES are small files stored on your device (computer or mobile device).
                    DATA CONTROLLER means a natural or legal person who (either alone or jointly
                    or in common with other persons) determines the purposes for which and the
                    manner in which any personal data are, or are to be, processed. For the purpose
                    of this Privacy Policy, we are a Data Controller of your data.
                    DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal
                    person who processes the data on behalf of the Data Controller. We may use the
                    services of various Service Providers in order to process your data more
                    effectively.
                    DATA SUBJECT is any living individual who is the subject of Personal Data.
                    THE USER is the individual using our Service. The User corresponds to the Data
                    Subject, who is the subject of Personal Data.
                    CHARGEBACK is a return of money to a payer of some transaction, especially a
                    credit card transaction Most commonly the payer is a customer. The chargeback
                    reverses a money transfer from the customer's bank account, line of credit, or
                    credit card.
                    3. Information Collection and Use:
                    We reserve the right to collect different types of information, and you “the user”
                    are obliged to furnish us with the required information for various purposes, in
                    order to help us provide and improve our Service to you.
                    4. Types of Data Collected:
                    Personal Data
                    While using our Service, we may require you to provide certain personally
                    identifiable information that can be used to contact or identify you (“Personal
                    Data”). Personal information may include, but not limited to:
                    a. Email address
                    b. First name and last name
                    c. Phone number
                    d. Address, State, Province, ZIP/Postal code, City
                    e. Cookies and Usage Data
                    f. Banking Information
                    We may use your Personal Data to contact you with newsletters, marketing or
                    promotional materials and other information that may be of interest to you. You
                    may opt out of receiving any, or all, of these communications from us by
                    following the unsubscribe link or sending us an email at
                    support@shoptinga.online.
                    Usage Data
                    We may also collect information that your browser sends whenever you visit our
                    Service or when you access Service by or through a mobile device (“Usage
                    Data”).
                    This Usage Data may include information such as your computer's Internet
                    Protocol address (e.g. IP address), browser type, browser version, the pages of
                    our Service that you visit, the time and date of your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic data.
                    When you access Service with a mobile device, this Usage Data may include
                    information such as the type of mobile device you use, your mobile device unique
                    ID, the IP address of your mobile device, your mobile operating system, the type
                    of mobile Internet browser you use, unique device identifiers and other diagnostic
                    data.
                    Location Data
                    We may use and store information about your location if you give us permission
                    to do so (“Location Data”). We use this data to provide features of our Service, to
                    improve and customize our Service.
                    You can enable or disable location services when you use our Service at any time
                    by way of your device settings.
                    KYC Information
                    We may need to verify your identity to enable us to provide certain services to
                    you. Like processing payments. We would usually require a valid ID, or BVN or
                    passport number. This is to meet regulatory requirements to provide such
                    services.
                    Tracking Cookies Data
                    We use cookies and similar tracking technologies to track the activity on our
                    Service and we hold certain information.
                    Cookies are files with a small amount of data which may include an anonymous
                    unique identifier. Cookies are sent to your browser from a website and stored on
                    your device. Other tracking technologies are also used such as beacons, tags and
                    scripts to collect and track information and to improve and analyse our Service.
                    You may instruct your browser to refuse all cookies or to indicate when a cookie
                    is being sent. However, if you do not accept cookies, you may not be able to use
                    some portions of our Service.
                    Examples of Cookies we use:
                    g. Session Cookies: We use Session Cookies to operate our Service.
                    h. Preference Cookies: We use Preference Cookies to remember your
                    preferences and various settings.
                    i. Security Cookies: We use Security Cookies for security purposes.
                    j. Advertising Cookies: Advertising Cookies are used to serve you with
                    advertisements that may be relevant to you and your interests.
                    5. Use of Data:
                    Shoptinga Online uses the obtained data for various purposes:
                    a. to provide and maintain our Service;
                    b. to notify you about changes to our Service;
                    c. to allow you to participate in interactive features of our Service when you
                    choose to do so;
                    d. to provide customer support;
                    e. to gather analysis or valuable information so that we can improve our
                    Service;
                    f. to monitor the usage of our Service;
                    g. to detect, prevent and address technical issues;
                    h. to fulfill any other purpose for which you provide it;
                    i. to carry out our obligations and enforce our rights arising from any
                    contracts entered into between you and us, including for billing and
                    collection;
                    j. to provide you with notices about your account and/or subscription,
                    including expiration and renewal notices, email-instructions, etc.;
                    k. to provide you with news, special offers and general information about
                    other goods, services and events which we offer that are similar to those
                    that you have already purchased or enquired about unless you have opted
                    not to receive such information;
                    l. in any other way we may describe when you provide the information;
                    m. for any other purpose with your consent.
                    6. Retention of Data:
                    We will retain your Personal Data only for as long as is necessary for the
                    purposes set out in this Privacy Policy. We will retain and use your Personal Data
                    to the extent necessary to comply with our legal obligations (for example, if we
                    are required to retain your data to comply with applicable laws), resolve disputes,
                    and enforce our legal agreements and policies.
                    We will also retain Usage Data for internal analysis purposes. Usage Data is
                    generally retained for a shorter period, except when this data is used to
                    strengthen the security or to improve the functionality of our Service, or we are
                    legally obligated to retain this data for longer time periods.
                    7. Transfer of Data:
                    Your information, including Personal Data, may be transferred to – and
                    maintained on – computers located outside of your state, province, country or
                    other governmental jurisdiction where the data protection laws may differ from
                    those of your jurisdiction.
                    If you are located outside the United States and choose to provide information to
                    us, please note that we transfer the data, including Personal Data, to the United
                    States and process it there.
                    Your consent to this Privacy Policy followed by your submission of such
                    information represents your agreement to that transfer.
                    Shoptinga Online will take all the steps reasonably necessary to ensure that your
                    data is treated securely and in accordance with this Privacy Policy and no
                    transfer of your Personal Data will take place to an organisation or a country
                    unless there are adequate controls in place including the security of your data
                    and other personal information.
                    8. Disclosure of Data:
                    We may disclose personal information that we collect, or you provide:
                    a. Disclosure for Law Enforcement.
                    Under certain circumstances, we may be required to disclose your
                    Personal Data if required to do so by law or in response to valid requests
                    by public authorities.
                    b. Business Transaction
                    If we or our subsidiaries are involved in a merger, acquisition or asset sale,
                    your Personal Data may be transferred.
                    c. Other cases. We may disclose your information also:
                    i. to our subsidiaries and affiliates;
                    ii. to fulfill the purpose for which you provide it;
                    iii. for the purpose of including your company’s logo on our website;
                    iv. for any other purpose disclosed by us when you provide the
                    information;
                    v. with your consent in any other cases;
                    vi. if we believe disclosure is necessary or appropriate to protect the
                    rights, property, or safety of the Company, our customers, or others.
                    9. Security of Data:
                    The security of your data is important to us but remember that no method of
                    transmission over the Internet or method of electronic storage is 100% secure.
                    While we strive to use commercially acceptable means to protect your Personal
                    Data, we cannot guarantee its absolute security.
                    10.Your Data Protection Rights Under General Data Protection Regulation (GDPR):
                    If you are a resident of the European Union (EU) and European Economic Area
                    (EEA), you have certain data protection rights, covered by GDPR. – See more at
                    https://eur-lex.europa.eu/eli/reg/2016/679/oj
                    We aim to take reasonable steps to allow you to correct, amend, delete, or limit
                    the use of your Personal Data.
                    If you wish to be informed what Personal Data we hold about you and if you want
                    it to be removed from our systems, please email us at support@shoptinga.online.
                    In certain circumstances, you have the following data protection rights:
                    a. the right to access, update or to delete the information we have on you;
                    b. the right of rectification. You have the right to have your information
                    rectified if that information is inaccurate or incomplete;
                    c. the right to object. You have the right to object to our processing of your
                    Personal Data;
                    d. the right of restriction. You have the right to request that we restrict the
                    processing of your personal information;
                    e. the right to data portability. You have the right to be provided with a copy
                    of your Personal Data in a structured, machine-readable and commonly
                    used format;
                    f. the right to withdraw consent. You also have the right to withdraw your
                    consent at any time where we rely on your consent to process your
                    personal information;
                    Please note that we may require you to verify your identity before responding to
                    such requests. Please note, we may not be able to provide Service without some
                    necessary data.
                    You reserve the right to complain to a Data Protection Authority about our
                    collection and use of your Personal Data. For more information, please contact
                    your local data protection authority in the European Economic Area (EEA).
                    11.Your Data Protection Rights under the California Privacy Protection Act
                    (CalOPPA):
                    CalOPPA is the first state law in the nation to require commercial websites and
                    online services to post a privacy policy. The law’s reach stretches well beyond
                    California to require a person or company in the United States (and conceivable
                    the world) that operates websites collecting personally identifiable information
                    from California consumers to post a conspicuous privacy policy on its website
                    stating exactly the information being collected and those individuals with whom
                    it is being shared, and to comply with this policy. – See more at:
                    https://consumercal.org/about-cfc/cfc-education-foundation/california-online-pri
                    vacy-protection-act-caloppa-3/
                    According to CalOPPA we agree to the following:
                    a. users can visit our site anonymously;
                    b. our Privacy Policy link includes the word “Privacy”, and can easily be found
                    on the page specified above on the home page of our website;
                    c. users will be notified of any privacy policy changes on our Privacy Policy
                    Page;
                    d. users are able to change their personal information by emailing us at
                    support@shoptinga.online
                    Our Policy on “Do Not Track” Signals:
                    We honor Do Not Track signals and do not track, plant cookies, or use advertising
                    when a Do Not Track browser mechanism is in place. Do Not Track is a
                    preference you can set in your web browser to inform websites that you do not
                    want to be tracked.
                    You can enable or disable Do Not Track by visiting the Preferences or Settings
                    page of your web browser.
                    12.Your Data Protection Rights under the California Consumer Privacy Act (CCPA):
                    If you are a California resident, you are entitled to learn what data we collect
                    about you, ask to delete your data and not to sell (share) it. To exercise your data
                    protection rights, you can make certain requests and ask us:
                    a. What personal information we have about you. If you make this request,
                    we will return to you:
                    i. The categories of personal information we have collected about
                    you.
                    ii. The categories of sources from which we collect your personal
                    information.
                    iii. The business or commercial purpose for collecting or selling your
                    personal information.
                    iv. The categories of third parties with whom we share personal
                    information.
                    v. The specific pieces of personal information we have collected
                    about you.
                    vi. A list of categories of personal information that we have sold, along
                    with the category of any other company we sold it to. If we have not
                    sold your personal information, we will inform you of that fact.
                    vii. A list of categories of personal information that we have disclosed
                    for a business purpose, along with the category of any other
                    company we shared it with.
                    b. Please note, you are entitled to ask us to provide you with this information
                    up to two times in a rolling twelve-month period. When you make this
                    request, the information provided may be limited to the personal
                    information we collected about you in the previous 12 months.
                    c. To delete your personal information . If you make this request, we will
                    delete the personal information we hold about you as of the date of your
                    request from our records and direct any service providers to do the same.
                    In some cases, deletion may be accomplished through de-identification of
                    the information. If you choose to delete your personal information, you
                    may not be able to use certain functions that require your personal
                    information to operate.
                    d. To stop selling your personal information. We don't sell or rent your
                    personal information to any third parties for any purpose. You are the only
                    owner of your Personal Data and can request disclosure or deletion at any
                    time.
                    Please note, if you ask us to delete or stop selling your data, it may impact your
                    experience with us, and you may not be able to participate in certain programs or
                    membership services which require the usage of your personal information to
                    function. But in no circumstances, we will discriminate against you for exercising
                    your rights.
                    To exercise your California data protection rights described above, please send
                    your request(s) by one of the following means:
                    By email: support@shoptinga.online
                    Your data protection rights, described above, are covered by the CCPA, short for
                    the California Consumer Privacy Act. To find out more, visit the official California
                    Legislative Information website. The CCPA took effect on 01/01/2020.
                    13. Service Providers:
                    We may employ third party companies and individuals to facilitate our Service
                    (“Service Providers ”), provide Service on our behalf, perform Service-related
                    services or assist us in analyzing how our Service is used.
                    These third parties have access to your Personal Data only to perform these
                    tasks on our behalf and are obligated not to disclose or use it for any other
                    purpose.
                    14. Analytics:
                    We may use third-party Service Providers to monitor and analyze the use of our
                    Service.
                    Google Analytics
                    Google Analytics is a web analytics service offered by Google that tracks and
                    reports website traffic. Google uses the data collected to track and monitor the
                    use of our Service. This data is shared with other Google services. Google may
                    use the collected data to contextualize and personalize the ads of its own
                    advertising network.
                    For more information on the privacy practices of Google, please visit the Google
                    Privacy Terms web page: https://policies.google.com/privacy?hl=en
                    We also encourage you to review Google's policy for safeguarding your data:
                    https://support.google.com/analytics/answer/6004245.
                    Cloudflare analytics
                    Cloudflare analytics is a web analytics service operated by Cloudflare Inc. Read
                    the Privacy Policy here: https://www.cloudflare.com/privacypolicy/
                    Mixpanel
                    Mixpanel is provided by Mixpanel Inc.
                    You can prevent Mixpanel from using your information for analytics purposes by
                    opting-out. To opt-out of Mixpanel service, please visit this page:
                    https://mixpanel.com/optout/
                    For more information on what type of information Mixpanel collects, please visit
                    the Terms of Use page of Mixpanel: https://mixpanel.com/terms/
                    15. CI/CD tools:
                    We may use third-party Service Providers to automate the development process
                    of our Service.
                    GitHub
                    GitHub is provided by GitHub, Inc.
                    GitHub is a development platform to host and review code, manage projects, and
                    build software.
                    For more information on what data GitHub collects for what purpose and how the
                    protection of the data is ensured, please visit GitHub Privacy Policy page:
                    https://help.github.com/en/articles/github-privacy-statement.
                    16. Advertising:
                    We may use third-party Service Providers to show advertisements to you to help
                    support and maintain our Service.
                    Google AdSense DoubleClick Cookie
                    Google, as a third party vendor, uses cookies to serve ads on our Service.
                    Google's use of the DoubleClick cookie enables it and its partners to serve ads to
                    our users based on their visit to our Service or other websites on the Internet.
                    You may opt out of the use of the DoubleClick Cookie for interest-based
                    advertising by visiting the Google Ads Settings web page:
                    http://www.google.com/ads/preferences/
                    17. Behavioral Remarketing:
                    Shoptinga Online uses remarketing services to advertise on third party websites
                    to you after you visited our Service. We and our third-party vendors use cookies
                    to inform, optimize and serve ads based on your past visits to our Service.
                    Google Ads (AdWords)
                    Google Ads (AdWords) remarketing service is provided by Google Inc.
                    You can opt-out of Google Analytics for Display Advertising and customize the
                    Google Display Network ads by visiting the Google Ads Settings page:
                    http://www.google.com/settings/ads
                    Google also recommends installing the Google Analytics Opt-out Browser Add-on
                    – https://tools.google.com/dlpage/gaoptout – for your web browser. Google
                    Analytics Opt-out Browser Add-on provides visitors with the ability to prevent
                    their data from being collected and used by Google Analytics.
                    For more information on the privacy practices of Google, please visit the Google
                    Privacy Terms web page: https://policies.google.com/privacy?hl=en
                    Twitter
                    Twitter remarketing service is provided by Twitter Inc.
                    You can opt-out from Twitter's interest-based ads by following their instructions:
                    https://support.twitter.com/articles/20170405
                    You can learn more about the privacy practices and policies of Twitter by visiting
                    their Privacy Policy page: https://twitter.com/privacy
                    Facebook
                    Facebook remarketing service is provided by Facebook Inc.
                    You can learn more about interest-based advertising from Facebook by visiting
                    this page: https://www.facebook.com/help/164968693837950
                    To opt-out from Facebook's interest-based ads, follow these instructions from
                    Facebook: https://www.facebook.com/help/568137493302217
                    Facebook adheres to the Self-Regulatory Principles for Online Behavioural
                    Advertising established by the Digital Advertising Alliance. You can also opt-out
                    from Facebook and other participating companies through the Digital Advertising
                    Alliance in the USA http://www.aboutads.info/choices/, the Digital Advertising
                    Alliance of Canada in Canada http://youradchoices.ca/ or the European
                    Interactive Digital Advertising Alliance in Europe
                    http://www.youronlinechoices.eu/, or opt-out using your mobile device settings.
                    For more information on the privacy practices of Facebook, please visit
                    Facebook's Data Policy: https://www.facebook.com/privacy/explanation
                    18. Payments:
                    We may provide paid products and/or services within Service. In that case, we
                    use third-party services for payment processing (e.g. payment processors).
                    We will not store or collect your payment card details. That information is
                    provided directly to our third-party payment processors whose use of your
                    personal information is governed by their Privacy Policy. These payment
                    processors adhere to the standards set by PCI-DSS as managed by the PCI
                    Security Standards Council, which is a joint effort of brands like Visa, Mastercard,
                    American Express and Discover. PCI-DSS requirements help ensure the secure
                    handling of payment information.
                    We work with the following Payment Processors:
                    Stripe:
                    Their Privacy Policy can be viewed at: https://stripe.com/us/privacy
                    19. Links to Other Sites:
                    Our Service may contain links to other sites that are not operated by us. If you
                    click a third party link, you will be directed to that third party's site. We strongly
                    advise you to review the Privacy Policy of every site you visit.
                    We have no control over and assume no responsibility for the content, privacy
                    policies or practices of any third party sites or services.
                    20.Children's Privacy:
                    Our Services are not intended for use by children under the age of 13 (“Children
                    ”).
                    We do not knowingly collect personally identifiable information from Children
                    under 13. If you become aware that a Child has provided us with Personal Data,
                    please contact us. If we become aware that we have collected Personal Data
                    from Children without verification of parental consent, we take steps to remove
                    that information from our servers.
                    21.Changes to This Privacy Policy:
                    We may update our Privacy Policy from time to time. We will notify you of any
                    changes by posting the new Privacy Policy on this page.
                    We shall inform you via email and/or a prominent notice on our Service, prior to
                    the change becoming effective and update “effective date” at the top of this
                    Privacy Policy.
                    You are advised to review this Privacy Policy periodically for any changes.
                    Changes to this Privacy Policy are effective when they are posted on this page.
                    22.Contact Us
                    If you have any questions about this Privacy Policy, please contact us:
                    By email: support@shoptinga.online.
                    FTC Disclosure
                    We’re required by law to comply with the Federal Trade Commission’s 16 CFR, Part 255:
                    “Guides Concerning the Use of Endorsements and Testimonials in Advertising.”
                    Additionally, we believe that being transparent with our audience is the right thing to do.
                    So we’ve created this disclosure page to let you know that we do make money from this
                    website.
                    If you’re using this website, then you should know:
                    ● We get direct benefit from every post and page on this website. Whether that is
                    monetary or simply the joy of helping others, we benefit from it.
                    ● If you see an external link with a prefix /refer/, then it’s an affiliate link. That
                    means that if you click on that link and purchase a product, we’ll receive a referral
                    commission at no-additional cost to you.
                    ● We do write about other products and websites associated with this brand, so
                    that means those projects get additional PR and exposure.
                    It’s important that we emphasize that the words on this website are not influenced by
                    any sort of compensation. We only recommend products or services that we use
                    personally and/or believe will add value to our customers and readers.
                    If you have any questions regarding the above, then please don’t hesitate to contact us
                    by using our “Contact Us” page.
                </div>
            </div>
            <Footer />
        </div>
    </div>
  )
}

export default TermsOfService